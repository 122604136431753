import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionIfVariable extends Action {
	static _group = "flow_control";
	static _label = "Condizione Variabile";
	static _fields = {
		variable: {
			label: "Variabile",
			type: "select",
			value: "",
			options: {},
		},
		comparison: { label: "Comparatore", type: "select", value: "eq", options: ActionIfVariable._comparisons },
		value: { label: "Valore", type: "text", value: 0 },
	};

	get fields() {
		let options = {};
		this.container.story.variables.forEach(a => options[a.uuid] = a.name);
		return Object.assign(this.constructor._fields, { variable: Object.assign(this.constructor._fields.variable, { options }) });
	}

	get abstract() {
		if (!this.data.variable.value) return "∅";
		return this.container.story.getVariable(this.data.variable.value).name + " "
			+ ActionIfVariable._comparisons[this.data.comparison.value].split(" ")[0] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		let v = this.container.story.getVariable(this.data.variable.value)
		let f = false;
		switch (this.data.comparison.value) {
			case "eq":
				f = (v.value == this.data.value.value);
				break;
			case "ne":
				f = (v.value != this.data.value.value);
				break;
			case "gt":
				f = (v.value > this.data.value.value);
				break;
			case "ge":
				f = (v.value >= this.data.value.value);
				break;
			case "lt":
				f = (v.value < this.data.value.value);
				break;
			case "le":
				f = (v.value <= this.data.value.value);
				break;
		}
		return (f ? null : Runnable.JUMP);
	}
}

export default ActionIfVariable;
