import Runnable from "@/classes/Runnable.js"

import L from "@/Logic.js"

class Actor extends Runnable {
	constructor(d = {}) {
		super(d);
		this.uuid = (d.uuid || L.uuid());
		this.x = parseInt(d.x);
		this.y = parseInt(d.y);
		this.name = (d.name || "New Actor");
		this.direction = (d.direction || "down");
		this.tile = (d.tile || 0);
		this.cursor = 0;
	}

	clone() {
		return new Actor(this);
	}

	toJSON() {
		return {
			list: this.list,
			uuid: this.uuid,
			name: this.name,
			trigger: this.trigger,
			x: this.x,
			y: this.y,
			directories: this.direction,
			tile: this.tile,
		}
	}

}

export default Actor;
