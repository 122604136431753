import { reactive } from "vue"

import Story from "@/classes/Story.js"
import Scene from "@/classes/Scene.js"
import Figure from "@/classes/Figure.js"

let options = localStorage.getItem("options");
options = (options ? JSON.parse(options) : {});

var Store = reactive({

	endpoint: process.env.VUE_APP_ENDPOINT,
	assetsPath: process.env.VUE_APP_ASSETS,
	name: "Guro Land",

	menu: false,
	main: { title: "Trallallibro", back: null, tabs: null },
	notices: 0,
	messages: reactive([]),
	notfound: false,

	edit: {
		// Story
		story: null,
		scenes: null,
		// Scene
		scene: null,
		entrypoints: null,
		items: null,
		background: null,
		foreground: null,
		actor: null,
	},

	figure: null,
	is_admin: false,
	cachedBook: null,
	cachedUser: null,
	options,
	share: null,
	modalDialog: null,

	story: null,
	scene: null,

	showMenu() {
		this.menu = true;
	},

	hideMenu() {
		this.menu = false;
	},

	async rGet(action, params = {}) {
		return await this._fetch(action, params);
	},

	async rDelete(action, params = {}) {
		return await this._fetch(action, params, { method: "DELETE" });
	},

	async rPost(action, f, params = {}) {
		return await this._submit(action, f, params, { method: "POST" });
	},

	async rPut(action, f, params = {}) {
		return await this._submit(action, f, params, { method: "PUT" });
	},

	async rPatch(action, f, params = {}) {
		return await this._submit(action, f, params, { method: "PATCH" });
	},

	async _fetch(action, params = {}, options = {}) {

		let query = [];
		for (let i in params) query.push(i+"="+encodeURIComponent(params[i]));

		let opt = {
			method: (options.method || "GET"),
			credentials: "include",
		};

		let res = await fetch(this.endpoint+action+"?"+query.join("&"), opt);

		let data;

		try {
			data = await res.json();
		} catch (e) {
			console.error(e);
			alert("Si è verificato un errore del server");
			window.location = "/";
		}

		if (data.error) {
			if (data.reason === "not-logged") {
				alert("Devi accedere per poter giocare");
				location = "https://www.nintendoclub.it/forumz/index.php?action=login";
			} else if (data.reason === "notauthorized") {
				Store.unsetUser();
				throw new Error();
			} else if (data.reason === "notfound") {
				this.notfound = true;
				throw new Error();
			} else {
				this.pushMessage(data.errorMessage, "error");
				throw new Error(data.errorMessage);
			}
		}

		return data.response;
	},

	async _submit(action, f, params = {}, options = {}) {

		let query = [];
		for (let i in params) query.push(i+"="+encodeURIComponent(params[i]));

		let opt = {
			method: (options.method || "POST"),
			credentials: "include",
		};

		if (typeof f === "object" && f.constructor.name === "FormData") {
			opt.body = f;
		} else {
			opt.body = JSON.stringify(f);
			opt.headers = {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			}
		}

		let res = await fetch(this.endpoint+action+"?"+query.join("&"), opt);

		let data = await res.json();

		if (data.error) {
			this.pushMessage(data.errorMessage, "error");
			throw new Error(data.errorMessage);
		}

		return data.response;

	},

	async fetchBook(id) {
		return await this.fetch("/book/"+id);
	},

	setFigure(figure = null) {
		if (figure) this.figure = new Figure(figure);
		localStorage.setItem("figure", JSON.stringify(this.figure));
	},

	unsetUser() {
		this.user = null;
		localStorage.removeItem("user");
	},

	setView(title, opts = {}) {
		document.title = this.main.title = title;
		this.main.back = (opts.back !== undefined ? opts.back : null);
		this.main.tabs = (opts.tabs !== undefined ? opts.tabs : null);
		this.main.actions = (opts.actions !== undefined ? opts.actions : null);
		if (opts.tabs) document.body.classList.add("doubleMenu"); else document.body.classList.remove("doubleMenu");

		if (window.gaEvent) {
			this.trackEvent('sceen_view', {
				page_title: title,
				page_location: 'https://app.trallallibro.com'+location.pathname,
			});
		} else {
			setTimeout(() => {
				this.trackEvent('sceen_view', {
					page_title: title,
					page_location: 'https://app.trallallibro.com'+location.pathname,
				});
			}, 1500);
		}
	},

	trackEvent(type, params) {
		if (window.gaEvent) window.gaEvent(type, params);
	},

	pushMessage(text, type = "success") {
		let ts = new Date;
		ts.setSeconds(ts.getSeconds() + 5);
		this.messages.push({ text, type, ts });
	},

	removeMessage(i) {
		this.messages.splice(i, 1);
	},

	cron() {
		let ts = new Date();
		while (this.messages.length && this.messages[0].ts < ts) this.messages.shift();
	},

	setOption(k, v) {
		this.options[k] = v;
		localStorage.setItem("options", JSON.stringify(this.options));
	},

	userFactory(d) {
		return new Figure(d);
	},

	dateFormat(d) {
		if (!d) return null;
		let m = d.match(/^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)$/);
		return m[3]+"/"+m[2]+"/"+m[1]+" "+m[4]+":"+m[5];
	},

	setShare(text) {
		this.share = [
			{
				name: "Facebook",
				url: "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(location.href)+"&title="+encodeURIComponent(text),
				class: "mdi mdi-facebook",
				color: "#1877F2",
			}, {
				name: "Twitter",
				url: "https://twitter.com/intent/tweet?source=webclient&original_referer="+encodeURIComponent(location.href)+"&text="+encodeURIComponent(text),
				class: "mdi mdi-twitter",
				color: "#1DA1F2",
			}
		];
	},

	unsetShare() {
		this.share = null;
	},

	showModalDialog(message, title = null) {
		this.modalDialog = { message, title, mode: "alert" };
	},

	showConfirmModalDialog(message, callback, title = null) {
		this.modalDialog = { message, title, callback, mode: "confirm" };
	},

	confirmModalDialog() {
		this.modalDialog.callback();
	},

	hideModalDialog() {
		this.modalDialog = null;
	},

	formValidation(form) {
		form.removeAttribute("novalidate");
		form.classList.add("validate");
	},

	sure(msg = "Sei sicuro?") {
		return confirm(msg);
	},

	assetUrl(id, cb = false) {
		return this.assetsPath+"/"+id+".webp"+(cb ? "?cb="+Date.now() : "");
	},

	// ### Story utils

	async storyLoad(id) {
		const res = await this.rGet("/story/"+id+"/edit");
		this.edit.story = new Story(res.story);
		this.edit.scenes = res.scenes.map(a => new Scene(this.edit, a));

		this.sceneReset();
	},

	async storyNew() {
		this.story = null;
		this.title = "Crea Storie";
	},

	storyReset() {
		this.edit.story = null;
		this.edit.scene = null;
	},

	// ### Scene utils

	async sceneLoad(id) {
		const res = await this.rGet("/scene/"+id);
		this.edit.entrypoints = res.entrypoints;
		this.edit.items = res.items;
		this.edit.background = res.background;
		this.edit.foreground = res.foreground;
		this.edit.actor = res.actor;
		this.edit.scene = new Scene(this.edit, res.scene);
	},

	sceneReset() {
		this.edit.scene = null;
	},


})

setInterval(() => Store.cron(), 1000);

export default Store;
