import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionTextMessage extends Action {
	static _group = Action.GROUP.interaction;
	static _label = "Messaggio";
	static _fields = {
		text: {
			label: "Testo",
			type: "text",
			value: ""
		},
	};

	get abstract() {
		if (this.data.text.value.length === 0) return "∅";

		if (this.data.text.value.length > 40) {
			return this.data.text.value.substr(0, 40)+"…";
		} else {
			return this.data.text.value;
		}
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideText();
			return;
		}

		this.flag = this.showText(this.data.text.value, this.container);

		return Runnable.PAUSE;
	}
}

export default ActionTextMessage;
