import Action from "@/classes/Action.js"

import G from "@/Game.js"

class ActionBattleQuit extends Action {
	static _group = Action.GROUP.battle;
	static _label = "Battle Quit*";
	static _fields = {};

	get abstract() {
		return "Battle";
	}

	action() {
		G.gameBattleQuit();
	}
}

export default ActionBattleQuit;
