import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionTextDialog extends Action {
	static _group = Action.GROUP.interaction;
	static _label = "Dialogo";
	static _fields = {
		text: {
			label: "Testo",
			type: "text",
			value: ""
		},
	};

	get abstract() {
		if (this.data.text.value.length === 0) return "∅";

		if (this.data.text.value.length > 40) {
			return this.data.text.value.substr(0, 40)+"…";
		} else {
			return this.data.text.value;
		}
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideDialog();
			return;
		}

		this.flag = this.showDialog(this.data.text.value);

		return Runnable.PAUSE;
	}
}

export default ActionTextDialog;
