import Action from "@/classes/Action.js"

class Runnable {

	// No operation
	static NOP = "nop";
	// Jump to next same indent istruction
	static JUMP = "jump";
	// Same action will be called again at next frame
	static CONTINUE = "continue";
	// Flow pause
	static PAUSE = "pause";
	// Flow stop
	static ABORT = "stop";
	// Flow reset
	static RESET = "reset";

	constructor(container, d = {}) {
		/// List of Action
		this.list = (d.list || []).filter(a => a).map(a => Action.factory(container, a));
		this.cursor = 0;
		this.status = Runnable.NOP;
		this.container = container;
	}

	toJSON() {
		return {
			list: this.list,
		}
	}

	clone() {
		return new Runnable(this.container, this);
	}

	get filled() {
		return !!this.list.length;
	}

	get empty() {
		return !this.list.length;
	}

	addAction(actionType) {
		this.list.push(Action.factory(this.container, actionType));
	}

	delAction(id) {
		if (typeof id === "number") {
			this.list.splice(id, 1);
		} else {
			this.list = this.list.filter(a => a !== id);
		}
	}

	_swapElements(arr, i1, i2) {
		arr[i1] = arr.splice(i2, 1, arr[i1])[0];
	}

	upAction(i) {
		let idx = this.list.indexOf(i);
		if (idx === 0) return;
		this._swapElements(this.list, idx-1, idx);
	}

	downAction(i) {
		let idx = this.list.indexOf(i);
		if (idx === this.list.length-1) return;
		this._swapElements(this.list, idx, idx+1);
	}

	run() {
		this.container.lock = null;
		for (; this.cursor < this.list.length; this.cursor++) {
			this.status = (this.list[this.cursor].action(this) || Runnable.NOP);

			if (this.status == Runnable.JUMP) {
				let i = this.list[this.cursor].data.indent;
				while (this.cursor+1 < this.list.length && this.list[this.cursor+1].data.indent > i) {
					++this.cursor
				}
			} else if (this.status == Runnable.CONTINUE) { // Same action will be called again at next frame
				this.container.lock = this;
				break;
			} else if (this.status == Runnable.PAUSE) { // Flow stop
				this.container.lock = this;
				break;
			} else if (this.status == Runnable.STOP) { // Flow stop and reset
				this.cursor = 0;
				break;
			} else if (this.status == Runnable.RESET) { // Flow restart
				this.cursor = -1;
			}
		}
		if (this.cursor == this.list.length) this.cursor = 0;
	}
}

export default Runnable;
