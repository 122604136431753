<template>
	<main>
		<router-view/>
		<MessagesComp/>
		<aside id="splash">
			<div>
				<span>Guro Land</span>
				<small>Loading&hellip;</small>
			</div>
		</aside>
		<!--div class="sound" style="width:16rem;margin:auto">
			<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1068317248&amp;color=%2300ff00&amp;auto_play=true&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"></iframe>
		</div-->
	</main>
</template>

<style lang="scss">

:root {
	--border-color: #666;
	--hover-color: #666;
	--lucid: rgba(32, 32, 32, .5);
	--lucid-em: rgba(64, 64, 64, .75);

	--primary-color: #0f0;
	--vibrant-color: #80ff80;

	--material-color: #6193cf;
	--magic-color: #00b377;
}

@import '~@mdi/font/css/materialdesignicons.css';

/* Atoms */

.text-center {
	text-align: center;
}

/* General */

* {
	scrollbar-width: thin;
	scrollbar-color: var(--primary-color) var(--border-color);
}

@font-face {
	font-family: ProximaNova;
	src: url(@/fonts/proxima_nova.ttf);
}

html {
	font: 16px ProximaNova, sans-serif;
	color: white;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	background-color: #444;
	background-image: url(@/img/carbon.png);
	background-attachment: fixed;

	section {
		max-width: 50rem;
		margin: 4rem auto 1rem auto;
		background: var(--lucid);
		padding: 0 1rem;
		overflow: auto;
		border-radius: 3px;
		border: 1px solid var(--border-color);
		&.minimal {
			border: none;
			background: none;
		}
	}

	.index {
		section a {
			display: block;
			background: var(--lucid);
			height: 6rem;
			margin: 1rem auto;
			text-align: center;
			border: 1px solid #666;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-radius: 3px;
			&:hover {
				background: var(--lucid-em);
			}
		}
	}
}

a {
	color: var(--primary-color);
	text-decoration: none;
	outline: none;
	transition: all .3s;
	cursor: pointer;
	&:hover {
		color: var(--vibrant-color);
	}
}

hr {
	border-style: solid;
	border-color: var(--border-color);
	border-width: 1px 0 0 0;
	margin: 1rem 0;
}

h1 {
	margin: 1rem 0;
	font-size: 3rem;
}

h2 {
	margin: 1rem 0;
	font-size: 1.5rem;
}

h3 {
	margin: 1rem 0;
	font-size: 1.25rem;
}

.color-material {
	color: var(--material-color);
}

.color-magic {
	color: var(--magic-color);
}

input[type=text],
input[type=number],
textarea,
select {
	display: block;
	background: white;
	box-sizing: border-box;
	border: 1px solid #ccc;
	font: inherit;
	padding: .25rem;
	width: 100%;
	max-width: 20rem;
}

.ck {
	color: black;
}

nav span.spacer {
	flex: auto;
}

button,
a.button {
	font-size: .8rem;
	border: 1px solid var(--border-color);
	display: inline-block;
	line-height: 2rem;
	padding: 0 1rem;
	background: var(--lucid);
	color: white;
	cursor: pointer;
	border-radius: 3px;
	text-transform: uppercase;
	transition: all .3s;
	&:hover {
		background: var(--lucid-em);
	}
}

button[type=submit],
a.button-submit {
	color: #0f0;
	&:hover {
		color: #af0;
	}
}

button.button-danger,
a.button-danger {
	color: #f44;
	&:hover {
		color: #f88;
	}
}

.buttons {
	display: flex;
	gap: 1rem;
	span {
		flex: auto;
	}
}

#splash {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #444;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	span {
		font-weight: bold;
	}

	small {
		display: block;
		text-transform: uppercase;
		font-size: .75rem;
	}
}

.mdi-2x {
	font-size: 2em;
}

.mdix {
	&-health {
		color: #e20800;
	}
	&-coins {
		color: #ffdd00;
	}
	&-space::before  {
		margin-right: .25rem;
	}
}

.action-list {
	display: flex;
	align-items: center;
	*:first-child {
		flex: auto;
	}
}

</style>

<script>

import Store from "@/Store.js"

import MessagesComp from "@/components/MessagesComp.vue"

export default {

	components: { MessagesComp },

	async mounted() {
		let res = await Store.rGet("/helo");
		Store.setFigure(res.figure);
		Store.is_admin = res.is_admin;
		this.$el.querySelector("#splash").remove();
	}
}

</script>
