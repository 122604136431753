import L from "@/Logic.js"

class Entrypoint {
	constructor(d = {}) {
		this.uuid = (d.uuid || L.uuid());
		this.x = parseInt(d.x);
		this.y = parseInt(d.y);
		this.name = (d.name || "New Entry Point");
		this.direction = (d.direction || "down");
	}

	clone() {
		return new Entrypoint(this);
	}
}

export default Entrypoint;
