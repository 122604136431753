import Action from "@/classes/Action.js"

import G from "@/Game.js"

class ActionModForeground extends Action {
	static _group = Action.GROUP.scene_control;
	static _label = "Modify Foreground*";
	static _fields = {
		position: {
			label: "Position",
			type: "text",
			value: "0,0"
		},
		foreground: {
			label: "Foreground",
			type: "number",
			value: 0
		},
	};

	get abstract() {
		return "Change "+this.data.position+" to "+this.data.foreground;
	}

	action() {
		let p = this.data.position.split(",");
		this.C.scene.map[p[1]][p[0]].f[0] = this.data.foreground;
		this.this.G.renderSingle(parseInt(p[0]), parseInt(p[1]));
		G.background.texture.update();
	}
}

export default ActionModForeground;
