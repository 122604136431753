<template>
<div class="index">

	<NavbarComp/>

	<section class="minimal">
		<h1 class="text-center"><i class="mdi mdi-sword-cross"></i> Guro Land</h1>
		<router-link to="/play"><i class="mdi mdi-2x mdi-sword-cross"></i><br/>Gioca</router-link>
		<router-link to="/figure"><i class="mdi mdi-2x mdi-account"></i><br/>Personaggio</router-link>
		<!--router-link to="/market"><i class="mdi mdi-2x mdi-shopping"></i><br/>Mercato</router-link-->
		<router-link to="/editor"><i class="mdi mdi-2x mdi-pencil"></i><br/>Crea Storie</router-link>
		<router-link to="/item"><i class="mdi mdi-2x mdi-hammer"></i><br/>Crea Oggetti</router-link>
		<router-link to="/asset" v-if="Store.is_admin"><i class="mdi mdi-2x mdi-image-area"></i><br/>Temi</router-link>
		<router-link to="/admin" v-if="Store.is_admin"><i class="mdi mdi-2x mdi-account-circle"></i><br/>Admin</router-link>
		<router-link to="/help"><i class="mdi mdi-2x mdi-help"></i><br/>Aiuto</router-link>
	</section>
</div>
</template>

<script>

import Store from "@/Store.js"
import NavbarComp from "@/components/NavbarComp.vue"

export default {
	components: { NavbarComp },
	data: () => ({
		Store,
	}),
}
</script>
