import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionIfItem extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Condizione Oggetto";
	static _fields = {
		item: {
			label: "Oggetto",
			type: "select",
			value: "",
			options: []
		},
		comparison: {
			label: "Comparatore",
			type: "select",
			value: "eq",
			options: ActionIfItem._comparisonsEx
		},
		value: {
			label: "Valore",
			type: "number",
			value: 1,
		},
	};

	get fields() {
		let options = {};
		Object.values(this.container.items).forEach(a => options[a.id_item] = a.name);
		return Object.assign(this.constructor._fields, { item: Object.assign(this.constructor._fields.item, { options }) });
	}

	get abstract() {
		return "Oggetto "
			+ this.container.items[this.data.item.value].name + " "
			+ ActionIfItem._comparisons[this.data.comparison.value] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		const v = this.container.figure.data.items.filter(a => a.i == this.data.item.value).length;
		let f = false;
		switch (this.data.comparison.value) {
			case "eq":
				f = (v == this.data.value.value);
				break;
			case "ne":
				f = (v != this.data.value.value);
				break;
			case "gt":
				f = (v > this.data.value.value);
				break;
			case "ge":
				f = (v >= this.data.value.value);
				break;
			case "lt":
				f = (v < this.data.value.value);
				break;
			case "le":
				f = (v <= this.data.value.value);
				break;
		}
		return (f ? null : Runnable.JUMP);
	}
}

export default ActionIfItem;
