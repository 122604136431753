import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionStop extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Interrompi esecuzione";

	// ###

	action() {
		return Runnable.STOP;
	}
}

export default ActionStop;
