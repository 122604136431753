import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionModHealth extends Action {
	static _group = Action.GROUP.mod_player;
	static _label = "Modifica Salute";
	static _fields = {
		value: {
			label: "Valore",
			type: "number",
			value: 0
		},
	};

	get abstract() {
		return (this.data.value.value > 0 ? "+" : "") + this.data.value.value + " Salute";
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideText();
			return;
		}

		this.container.modHealth(this.data.value.value);

		this.flag = true;

		if (this.data.value.value == 1) {
			this.showText("Hai recuperato 1 punto salute.");
		} else if (this.data.value.value >= 0) {
			this.showText(`Hai recuperato ${this.data.value.value} punti salute.`);
		} else if (this.data.value.value == -1) {
			this.showText("Hai perso 1 punto salute.");
		} else {
			this.showText(`Hai perso ${-this.data.value.value} punti salute.`);
		}

		return Runnable.PAUSE;
	}
}

export default ActionModHealth;
