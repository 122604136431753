import L from '@/Logic.js'

import * as PIXI from 'pixi.js'

import Store from "@/Store.js"

//import Action from '@/classes/Action.js'
import Story from '@/classes/Story.js'
import Scene from '@/classes/Scene.js'
import Runnable from '@/classes/Runnable.js'
//import Routine from '@/classes/Routine.js'*/

// Giocatore
const P = {
	x: 0,
	y: 0,
	d: 2,
	tile: 1,
	sprite: null,
	path: [],

	teleport(x, y, d) {
		this.x = x;
		this.y = y;
		this.d = d;
	},

	setDirection(dir) {
		if (P.direction == dir) return;
		P.direction = dir;
		this.sprite.texture.frame = new PIXI.Rectangle(4*L.s, G.dirMap2[dir]*L.s, 32, 32);
	},

	async useItem(id_item) {
		await G.api("item_use&id_item="+id_item, (res) => {
			G.figure.data.health = res.response.health;
			G.figure.data.coins = res.response.coins;
			G.figure.data.items = res.response.items;
			this.showHealth();
			this.showCoins();
			this.showItems();
		});
	},
}

const G = {
	vue: null,
	run: false,
	width: 20,
	height: 15,
	size: "20x15",
	s: 32,
	canvas: null,
	canvas2: null,
	backgroundTexture: null,
	level3Texture: null,
	battle: { init: true },
	land: {},
	textBoxStyle: null,
	dialogBoxStyle: null,
	app: null,
	frame: null,
	background: null,
	foreground: null,
	tileset: null,
	story: null,
	scene: null,
	dirMap: [3, 2, 0, 1],
	dirMap2: { down: 0, left: 1, right: 2, up: 3 },
	dirMap3: ["up", "left", "down", "right"],
	sprite: { actor: null, dx: 0, dy: 0 },
	keys: [], // Pulsanti premuti
	mode: "play",
	action: null,
	lock: null,
	backgroundColor: 0xfffde7,

	init(res, frame, vue) {

		this.vue = vue;
		this.story = new Story(res.story);
		this.items = res.items;
		this.figure = vue.figure;

		G.battle.stage = new PIXI.Container();

		let x = G.width * L.s;
		G.textBoxStyle = new PIXI.TextStyle({fontFamily : 'Arial', fontSize: 18, fill : 0x000000, wordWrap: true, wordWrapWidth: x - 32});
		G.dialogBoxStyle = new PIXI.TextStyle({fontFamily : 'Arial', fontSize: 18, fill : 0x000000, wordWrap: true, wordWrapWidth: x - 80});

		G.app = new PIXI.Application();
		G.frame = frame;
		G.frame.appendChild(G.app.view)

		G.land.stage = new PIXI.Container();
		G.land.main = new PIXI.Container();
		G.land.overlay = new PIXI.Container();

		G.land.stage.addChild(G.land.main, G.land.overlay);
		G.app.stage.addChild(G.land.stage);

		G.loadScene(this.story.entryscene);

	},

	stop() {
		G.run = false;
	},

	async loadScene(scene_id, entrypoint = null) {

		this.run = false;

		let res = await Store.rGet("/play/"+this.story.id_story+"/scene/"+scene_id);
		this.scene = new Scene(this, res.scene);
		G.background = res.background;
		G.foreground = res.foreground;
		G.tileset = await L.loadImage(`${process.env.VUE_APP_ASSETS}/char.png`, "tileset");

		//G.debug.printVariables();

		let ep = this.scene.getEntrypoint(entrypoint || this.scene.entrypoint);
		P.teleport(ep.x, ep.y, ep.direction);

		L.init(this.loadScene2, { scene: this.scene, isGame: true });
	},

	loadScene2() {

		G.canvas2 = document.createElement('canvas');
		G.canvas2.width = G.scene.width * L.s;
		G.canvas2.height = G.scene.height * L.s;
		G.level3Texture = PIXI.Texture.from(G.canvas2);
		L.renderLevel3(G.canvas2.getContext('2d'));

		L.canvas = G.canvas = document.createElement('canvas');
		G.canvas.width = G.scene.width * L.s;
		G.canvas.height = G.scene.height * L.s;
		G.backgroundTexture = PIXI.Texture.from(G.canvas);
		L.render(false);


		G.resize();

		// ### Cover

        // FIXME
		// if (G.scene.cover) {
		// 	document.body.classList.add("cover");
		// 	document.body.style.backgroundImage = `url(/files/${G.scene.id_scene}.jpg)`;
		// } else {
		// 	document.body.classList.remove("cover");
		// 	document.body.style.backgroundImage = "";
		// }

		// ### Stage

		G.land.main.removeChildren();

		G.land.x = G.land.y = 0;
		G.land.dx = G.land.dy = 0;

		G.land.px = P.x - Math.floor(G.width/2);
		if (G.land.px < 0) G.land.px = 0;
		else if (G.land.px > G.scene.width - G.width) G.land.px = G.scene.width - G.width;

		G.land.py = P.y - Math.floor(G.height/2);
		if (G.land.py < 0) G.land.py = 0;
		else if (G.land.py > G.scene.height - G.height) G.land.py = G.scene.height - G.height;

		G.backgroundTexture.update();
		G.backgroundSprite = new PIXI.Sprite(G.backgroundTexture);

		G.land.main.addChild(G.backgroundSprite);

		const playerTexture = PIXI.Texture.from(G.tileset.img);
		playerTexture.update();
		playerTexture.frame = new PIXI.Rectangle(128, 32*G.dirMap2[P.d], 32, 32);
		P.sprite = new PIXI.Sprite(playerTexture);
		P.sprite.x = P.x * L.s;
		P.sprite.y = P.y * L.s;
		G.land.main.addChild(P.sprite);

		let baseTexture = PIXI.BaseTexture.from(L.actorTiles.image);
		baseTexture.update();
		G.scene.actors.forEach(a => {
			a.sprite = new PIXI.Sprite(
				new PIXI.Texture(
					baseTexture,
					new PIXI.Rectangle(((a.tile % L.actorTiles.w) * 3 * L.s), (((Math.floor(a.tile / L.actorTiles.w)) * 4 + G.dirMap2[a.direction]) * L.s), 32, 32)
				)
			);
			a.sprite.x = a.x * L.s;
			a.sprite.y = a.y * L.s;
			G.land.main.addChild(a.sprite);
		});

		G.level3Texture.update();
		G.level3Sprite = new PIXI.Sprite(G.level3Texture);
		G.land.main.addChild(G.level3Sprite);

		// ### Start Loop

		G.run = true;

		G.scene.routines.filter(a => a.load).forEach(a => a.run());

		G.gameLoop();

	},

	resize() {

		let box = G.frame.getBoundingClientRect();

		G.width = Math.floor(box.width / L.s);
		G.height = Math.floor(box.height / L.s);
		G.size = G.width+"x"+G.height;

		G.app.renderer.resize(G.width*L.s, G.height*L.s)
	},

	gameLoop(ts) {

		if (!G.run) return;

		let delta = ts - G.ts;

		// G.debug_fps.firstChild.nodeValue = G.app.ticker.FPS.toFixed(1);

		if (G.mode === "battle") G.stateBattle(delta);
		else G.stateLand(delta);

		for (let i in G.keys) ++G.keys[i];
		G.click = null;

		G.app.render();
		G.ts = ts;

		requestAnimationFrame(G.gameLoop);
	},

	stateLand() {

		let b = G.land;
		if (b.dx < 0) b.dx += 4;
		else if (b.dx > 0) b.dx -= 4;
		else if (b.dy < 0) b.dy += 4;
		else if (b.dy > 0) b.dy -= 4;

		if (G.sprite.actor) {

			if (G.sprite.dx < 0) G.sprite.dx += 4;
			else if (G.sprite.dx > 0) G.sprite.dx -= 4;
			else if (G.sprite.dy < 0) G.sprite.dy += 4;
			else if (G.sprite.dy > 0) G.sprite.dy -= 4;

		} else if (b.dx == 0 && b.dy == 0) {

			if (G.lock) {
				if (G.lock.status == Runnable.CONTINUE) {
					G.lock.run();
					G.debug.printVariables();
				} else if (G.lock.status == Runnable.PAUSE) {
					if (G.keys.Space == 1 || G.click) {
						G.lock.run();
						G.debug.printVariables();
					} else if (G.lock.list[G.lock.cursor].state) {
						G.lock.list[G.lock.cursor].state()
					}
				}

			} else {

				let f = true;

				let action = G.scene.findEvent(P.x, P.y);
				if ((G.keys.Space == 1 || (G.click && G.click.x == P.x && G.click.y == P.y)) && (action && action.trigger == "over")) {

					action.run();
					G.debug.printVariables();
					f = false;

				} else if (G.keys.Space == 1 || G.click) {

					let xx = P.x, yy = P.y, dir = P.direction;

					if (G.click) {
						L.nears4f(P.x, P.y).forEach(a => {
							if (a[0] == G.click.x && a[1] == G.click.y) {
								xx = a[0];
								yy = a[1];
								dir = G.dirMap3[a[2]];
							}
						});
					} else if (P.direction == "up" && P.y > 0) yy--;
					else if (P.direction == "right" && P.x < G.scene.last_x) xx++;
					else if (P.direction == "down" && P.y < G.scene.last_y) yy++;
					else if (P.direction == "left" && P.x > 0) xx--;

					if (xx != P.x || yy != P.y) {
						action = G.scene.findActor(xx, yy)
						if (action) {
							P.setDirection(dir);
							action.run();
							f = false;
							G.debug.printVariables();
						} else {
							action = G.scene.findEvent(xx, yy)
							if (action) {
								if (action.trigger == "face") {
									P.setDirection(dir);
									action.run();
									f = false;
									G.debug.printVariables();
								}
							}
						}
					}
				}

				if (f) {

					if (G.click) {
						if (this.isWalkeable(G.click.x, G.click.y)) {
							P.path = G.buildPath(G.click.x, G.click.y, P.x, P.y, true);
						}
					}

					let d = null;
					if (P.path.length) d = P.path.shift();
					else if (G.keys.ArrowUp) d = 0;
					else if (G.keys.ArrowRight) d = 1;
					else if (G.keys.ArrowDown) d = 2;
					else if (G.keys.ArrowLeft) d = 3;

					let dir, flag = false;
					if (d == 0) {
						if (P.y > 0 && this.isWalkeable(P.x, P.y-1)) {
							flag = true;
							P.y--;
							G.sprite.dy = L.s;
							if (P.y - b.py < 4 && b.py > 0) {
								b.py--;
								b.dy = L.s;
							}
						}
						dir = "up";
					} else if (d == 2) {
						if (P.y < G.scene.last_y && this.isWalkeable(P.x, P.y+1)) {
							flag = true;
							P.y++;
							G.sprite.dy = -L.s;
							if (b.py + G.height - P.y <= 4 && b.py < L.h - G.height) {
								b.py++;
								b.dy = -L.s;
							}
						}
						dir = "down";
					} else if (d == 3) {
						if (P.x > 0 && this.isWalkeable(P.x-1, P.y)) {
							flag = true;
							P.x--;
							G.sprite.dx = L.s;
							if (P.x - b.px < 4 && b.px > 0) {
								b.px--;
								b.dx = L.s;
							}
						}
						dir = "left";
					} else if (d == 1) {
						if (P.x < G.scene.last_x && this.isWalkeable(P.x+1, P.y)) {
							flag = true;
							P.x++;
							G.sprite.dx = -L.s;
							if (b.px + G.width - P.x <= 4 && b.px < L.w - G.width) {
								b.px++;
								b.dx = -L.s;
							}
						}
						dir = "right";
					}

					if (dir !== undefined) {
						P.setDirection(dir);
					}

					if (flag) {
						G.sprite.actor = P;
					}
				}

			}
		}

		G.land.main.position.set(
			(G.scene.width < G.width ? (G.width - G.scene.width) / 2 * L.s : b.px * -L.s - b.dx),
			(G.scene.height < G.height ? (G.height - G.scene.height) / 2 * L.s : b.py * -L.s - b.dy),
		);

		if (G.sprite.actor) {
			G.sprite.actor.sprite.x = G.sprite.actor.x * L.s + G.sprite.dx;
			G.sprite.actor.sprite.y = G.sprite.actor.y * L.s + G.sprite.dy;
			if (G.sprite.dx == 0 && G.sprite.dy == 0) {
				G.sprite.actor.sprite.texture.frame = new PIXI.Rectangle(
					(((G.sprite.actor.tile % L.actorTiles.w) * 3 + 1) * L.s),
					(((Math.floor(G.sprite.actor.tile / L.actorTiles.w)) * 4 + G.dirMap2[G.sprite.actor.direction]) * L.s),
					32, 32
				);
				G.sprite.actor = null;
			} else {
				let dd = (Math.abs(G.sprite.dx || G.sprite.dy) < 16 ? 0 : 2);
				G.sprite.actor.sprite.texture.frame = new PIXI.Rectangle(
					(((G.sprite.actor.tile % L.actorTiles.w) * 3 + dd) * L.s),
					(((Math.floor(G.sprite.actor.tile / L.actorTiles.w)) * 4 + G.dirMap2[G.sprite.actor.direction]) * L.s),
					32, 32
				);
			}
		}

	},

	async gameOver(result) {
		G.run = false;
		await Store.rPut("/play/"+this.story.id_story, {
			result,
		});
		G.vue.gameOver();
	},

	// ### Actions

	keyDown(ev) {
		if (!G.run) return;
		ev.preventDefault();
		if (!G.keys[ev.code]) G.keys[ev.code] = 1;
	},

	keyUp(ev) {
		if (!G.run) return;
		ev.preventDefault();
		delete G.keys[ev.code];
	},

	click(ev) {
		if (!G.run) return;
		ev.preventDefault();
		if (ev.target == G.app.view) {
			let box = G.app.view.getBoundingClientRect();
			if (ev.touches) ev = ev.touches[0];
			G.click = {
				x: Math.floor((ev.clientX - box.left) / L.s) + G.land.px,
				y: Math.floor((ev.clientY - box.top) / L.s) + G.land.py,
			}
		} else if (ev.target.tagName == "A" && ev.target.dataset.item) {
			P.useItem(ev.target.dataset.item);
		}
	},

	// ### Utils

	isWalkeable(x, y) {
		if (G.scene.actors.find(a => a.x == x && a.y == y)) return false;
		if (!G.scene.map[y] || !G.scene.map[y][x]) return false;

		if (G.scene.map[y][x][1] !== null) {
			return false;
		} else if (G.scene.map[y][x][2] !== null) {
			return true;
		} else {
			return G.background.data.walkable[G.scene.map[y][x][0]];
		}
	},

	/// Spanning tree
	buildPath(targetX, targetY, startX, startY, limitToVisible = false) {

		let limit = limitToVisible ? [G.land.px, G.land.py, G.land.px + G.width - 1, G.land.py + G.height - 1] : [0, 0, L.w-1, L.h-1];
		let max = G.scene.width * G.scene.height;

		let map = new Array(G.scene.height);
		for (let i = 0; i < G.scene.height; i++) map[i] = new Array(G.scene.width);

		let pos = [targetX, targetY];
		map[pos[1]][pos[0]] = 0;
		let queue = [pos];

		let p = queue.shift();
		do {
			let v = map[p[1]][p[0]]+1;
			L.nears4fex(p[0], p[1], ...limit).forEach(a => {
				if (map[a[1]][a[0]] === undefined) {
					if (G.isWalkeable(a[0], a[1])) {
						map[a[1]][a[0]] = v;
						queue.push(a);
					} else {
						map[a[1]][a[0]] = max;
					}
				}
			});
			p = queue.shift();
		} while (p);

		let path = [];
		p = [startX, startY];
		if (map[p[1]][p[0]] !== undefined) {
			let v = max;
			do {
				L.nears4fex(p[0], p[1], ...limit).forEach(a => {
					if (map[a[1]][a[0]] < v) {
						v = map[a[1]][a[0]];
						p = a;
					}
				});
				path.push(p[2]);
			} while (v > 0);
		}

		return path;
	},

	async modCoins(value = 0) {
		const res = await Store.rPut("/play/coins", { value });
		this.vue.figure.data.coins = res.value;
	},

	async modHealth(value = 0) {
		const res = await Store.rPut("/play/health", { value });
		this.vue.figure.data.health = res.value;
	},

	async modItem(id_item, value) {
		const res = await Store.rPut("/play/item", { id_item, value });
		this.vue.figure.data.items = res.items;
	},

	measureText(text, type = "text") {
		let style = (type == "text" ? this.textBoxStyle : this.dialogBoxStyle);
		return PIXI.TextMetrics.measureText(text, style);
	},

	createTextBox() {
		let x = this.width * L.s;
		let innerHeight = 96; // (20 * 4 rows) + (8 * 2 padding) = 96
		let bottomPosition = this.height * L.s - innerHeight - 8;

		let textBox = new PIXI.Graphics();
		textBox.beginFill(this.backgroundColor)
			.drawRoundedRect(8, 0, x-16, innerHeight, 16)
			.endFill();
		textBox.visible = false;
		textBox.position.set(0, bottomPosition);

		let textText = new PIXI.Text('', this.textBoxStyle);
		textText.position.set(16, 8);
		textBox.addChild(textText);

		return textBox;
	},

	createDialogBox() {
		let x = G.width * L.s;
		let innerHeight = 96; // (20 * 4 rows) + (8 * 2 padding) = 96
		let bottomPosition = G.height * L.s - innerHeight - 8;

		let dialogBox = new PIXI.Graphics();
		dialogBox.beginFill(G.backgroundColor)
			.moveTo(96, 0)
			.lineTo(x-96, 0)
			.bezierCurveTo(x+32, 0, x, innerHeight/2, x-32, innerHeight/2)
			.bezierCurveTo(x, innerHeight/2, x, innerHeight, x-96, innerHeight)
			.lineTo(96, innerHeight)
			.bezierCurveTo(0, innerHeight, 0, innerHeight/2, 32, innerHeight/2)
			.bezierCurveTo(0, innerHeight/2, -32, 0, 96, 0)
			.endFill();
		dialogBox.visible = false;
		dialogBox.position.set(0, bottomPosition);

		let dialogText = new PIXI.Text('', G.dialogBoxStyle);
		dialogText.position.set(40, 8);
		dialogBox.addChild(dialogText);

		return dialogBox;
	},

	debug: {
		printVariables() {
			console.log(G.story.variables);
		}
	},

}

/*
 *
var G2 = {

	P,
	backgroundColor: 0xfffde7,


	load(url) {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.onload = () => resolve(JSON.parse(xhr.responseText));
			xhr.onerror = () => reject(xhr.statusText);
			xhr.send();
		});
	},

	send(url, post) {
		let form = new FormData();
		for (let i in post) form.append(i, post[i]);

		fetch(url, {
			method: "POST",
			body: form,
		});
	},

	async init(frame, story, figure) {

		C.isGame = true;

		C.story = story;
		C.figure = figure;

		//

		L.canvas = G.canvas = document.createElement('canvas');
		G.canvas2 = document.createElement('canvas');

		G.backgroundTexture = PIXI.Texture.from(G.canvas);
		G.level3Texture = PIXI.Texture.from(G.canvas2);

		G.battle.stage = new PIXI.Container();

		let x = G.width * L.s;
		G.textBoxStyle = new PIXI.TextStyle({fontFamily : 'Arial', fontSize: 18, fill : 0x000000, wordWrap: true, wordWrapWidth: x - 32});
		G.dialogBoxStyle = new PIXI.TextStyle({fontFamily : 'Arial', fontSize: 18, fill : 0x000000, wordWrap: true, wordWrapWidth: x - 80});

		G.app = new PIXI.Application();
		G.frame = frame;
		G.frame.appendChild(G.app.view)

		G.land.stage = new PIXI.Container();
		G.land.main = new PIXI.Container();
		G.land.overlay = new PIXI.Container();

		G.land.stage.addChild(G.land.main, G.land.overlay);
		G.app.stage.addChild(G.land.stage);

//		G.loadScene(C.story.entryscene);
	},

	async loadScene(scene_id, entrypoint = null) {

		G.run = false;

		let res = await G.load("/api.php?do=game&action=scene&id="+scene_id);
		G.scene = new Scene(res.response.scene.id_scene, res.response.scene.name, res.response.scene.data);
		C.background = res.response.background;
		C.foreground = res.response.foreground;
		G.tileset = await L.loadImage(`/assets/char.png`, "tileset");

		P.showCoins();
		P.showHealth();
		P.showItems();
		G.debug.printVariables();

		let ep = G.scene.getEntrypoint(entrypoint || G.scene.entrypoint);
		P.x = ep.x;
		P.y = ep.y;
		P.direction = ep.direction;

		L.init(G.loadScene2);
	},

	loadScene2() {

		G.canvas.width = G.scene.width * L.s;
		G.canvas.height = G.scene.height * L.s;

		G.canvas2.width = G.scene.width * L.s;
		G.canvas2.height = G.scene.height * L.s;

		L.render(false);
		L.renderLevel3(G.canvas2.getContext('2d'));

		G.resize();

		// ### Cover

		if (G.scene.cover) {
			document.body.classList.add("cover");
			document.body.style.backgroundImage = `url(/files/${G.scene.id_scene}.jpg)`;
		} else {
			document.body.classList.remove("cover");
			document.body.style.backgroundImage = "";
		}

		// ### Stage

		G.land.main.removeChildren();

		G.land.x = G.land.y = 0;
		G.land.dx = G.land.dy = 0;

		G.land.px = P.x - Math.floor(G.width/2);
		if (G.land.px < 0) G.land.px = 0;
		else if (G.land.px > G.scene.width - G.width) G.land.px = G.scene.width - G.width;

		G.land.py = P.y - Math.floor(G.height/2);
		if (G.land.py < 0) G.land.py = 0;
		else if (G.land.py > G.scene.height - G.height) G.land.py = G.scene.height - G.height;

		G.backgroundTexture.update();
		G.background = new PIXI.Sprite(G.backgroundTexture);
		G.land.main.addChild(G.background);

		let playerT = PIXI.Texture.from(G.tileset.img);
		playerT.update();
		playerT.frame = new PIXI.Rectangle(128, 32*G.dirMap[P.d], 32, 32);
		P.sprite = new PIXI.Sprite(playerT);
		P.sprite.x = P.x * L.s;
		P.sprite.y = P.y * L.s;
		G.land.main.addChild(P.sprite);

		let baseTexture = PIXI.BaseTexture.from(L.actorTiles.image);
		baseTexture.update();
		G.scene.actors.forEach(a => {
			a.sprite = new PIXI.Sprite(
				new PIXI.Texture(
					baseTexture,
					new PIXI.Rectangle(((a.tile % L.actorTiles.w) * 3 * L.s), (((Math.floor(a.tile / L.actorTiles.w)) * 4 + G.dirMap2[a.direction]) * L.s), 32, 32)
				)
			);
			a.sprite.x = a.x * L.s;
			a.sprite.y = a.y * L.s;
			G.land.main.addChild(a.sprite);
		});

		G.level3Texture.update();
		G.level3 = new PIXI.Sprite(G.level3Texture);
		G.land.main.addChild(G.level3);

		// ### Start Loop

		G.debug_fps = document.getElementById("debug_fps");

		G.run = true;

		G.scene.routines.filter(a => a.load).forEach(a => a.run());

		G.gameLoop();
	},

	stateBattle() {

		if (G.lock) {
			if (G.lock.status == Runnable.CONTINUE) {
				G.lock.run();
				G.debug.printVariables();
			} else if (G.lock.status == Runnable.PAUSE) {
				if (G.keys.Space == 1 || G.click) {
					G.lock.run();
					G.debug.printVariables();
				}
			}
		} else {
			G.gameBattleQuit();
		}
	},

	gameBattleStart() {

		if (G.battle.init) {

			let sprite = PIXI.Sprite.from('arance.jpg');
			G.battle.stage.addChild(sprite);

			// Routine

			G.battle.routine = new Routine();

			G.battle.routine.list.push(Action.factory({
				t: 'ActionBattleMessage',
				d: { text: "Alla pugna!!" },
			}));

			G.battle.routine.list.push(Action.factory({
				t: 'ActionBattleQuit',
			}));

			G.battle.init = false;

		}

		G.battle.routine.cursor = 0;
		G.battle.routine.run();

		G.app.stage.removeChild(G.land.stage);
		G.app.stage.addChild(G.battle.stage);
		G.mode = "battle";
	},

	gameBattleQuit() {
		G.app.stage.removeChild(G.battle.stage);
		G.app.stage.addChild(G.land.stage);
		G.mode = "play";
	},
};
*/

document.addEventListener("keydown", G.keyDown);
document.addEventListener("keyup", G.keyUp);
document.addEventListener("click", G.click);
document.addEventListener("touchstart", G.click);

window.addEventListener('resize', G.resize);

export default G;
