import Variable from "@/classes/Variable.js"

class Story {

	static pegis = ["violence", "language", "fear", "gambling", "sex", "drugs", "discrimination"];

	constructor(d = {}) {

		// Campi principali
		this.id_story = (d.id_story || 0);
		this.name = (d.name || "Nuova Storia");
		this.status = (d.status || "draft");
		this.description = (d.description || "");
		/// Lista delle scene, senza data
		this.scenes = (d.scenes || []);

		// Data

		let dd = (d.data || d);

		this.variables = (dd.variables || []).map(a => new Variable(a));
		this.entryscene = (dd.entryscene || null);
		this.cover = (dd.cover || false);
		this.pegi = {};
		Story.pegis.forEach(a => this.pegi[a] = false);
		if (dd.pegi) {
			if (dd.pegi instanceof Array) {
				dd.pegi.forEach(a => this.pegi[a] = true);
			} else {
				this.pegi = Object.assign(this.pegi, dd.pegi);
			}
		}

	}

	addVariable(name, type) {

		let initial;
		if (type === "string") {
			initial = "";
		} else if (type === "number") {
			initial = 0;
		} else if (type === "boolean") {
			initial = false;
		}

		let v = new Variable({
			name,
			type,
			initial,
		});
		this.variables.push(v);
		return v;
	}

	getVariable(uuid) {
		const v = this.variables.find(a => a.uuid == uuid);
		if (v === undefined) throw 'Story variable not found';
		return v;
	}

	delVariable(uuid) {
		this.variables = this.variables.filter(a => a.uuid != uuid);
	}

	modVariable(uuid, v) {
		let variable = this.variables.find(a => a.uuid === uuid);
		if (variable) variable.initial = v;
	}

	toJSON() {
		return {
			id_story: this.id_story,
			name: this.name,
			description: this.description,
			status: this.status,
			data: {
				variables: this.variables,
				entryscene: this.entryscene,
				pegi: Object.keys(this.pegi).filter(a => this.pegi[a]),
			}
		}
	}
}

export default Story;
