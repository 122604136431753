import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionIfAutovar extends Action {
	static _group = "flow_control";
	static _label = "Condizione Auto Variabile";
	static _fields = {
		autovar: { label: "Auto Variabile", type: "select", value: "a", options: { a: "A", b: "B", c: "C", d: "D" } },
		comparison: { label: "Comparatore", type: "select", value: "eq", options: ActionIfAutovar._comparisons },
		value: { label: "Valore", type: "text", value: 0 },
	};

	get abstract() {
		if (!this.data.variable.value) return "∅";
		return this.data.autovar.value.toUpperCase() + " "
			+ ActionIfAutovar._comparisons[this.data.comparison.value].split(" ")[0] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		const v = this.container.scene.autovar[this.data.autovar.value];
		let f = false;
		switch (this.data.comparison.value) {
			case "eq":
				f = (v == this.data.value.value);
				break;
			case "ne":
				f = (v != this.data.value.value);
				break;
			case "gt":
				f = (v > this.data.value.value);
				break;
			case "ge":
				f = (v >= this.data.value.value);
				break;
			case "lt":
				f = (v < this.data.value.value);
				break;
			case "le":
				f = (v <= this.data.value.value);
				break;
		}
		return (f ? null : Runnable.JUMP);
	}
}

export default ActionIfAutovar;
