import L from "@/Logic.js"

import Runnable from "@/classes/Runnable.js"

class RoutineEx extends Runnable {
	constructor(container, d = {}) {
		super(container, d);
		this.uuid = (d.uuid || L.uuid());
		this.name = (d.name || "Nuovo Evento");
		this.trigger = (d.trigger || "over");
		this.x = (d.x || null);
		this.y = (d.y || null);
	}

	clone() {
		return new RoutineEx(this.container, this);
	}

	toJSON() {
		return {
			list: this.list,
			uuid: this.uuid,
			name: this.name,
			trigger: this.trigger,
			x: this.x,
			y: this.y,
		}
	}
}

export default RoutineEx;
