/*
 *
 * Logica Play
 *
let directionMap = {
	up: 0,
	right: 1,
	down: 2,
	left: 3,
};
*/

import ActionTextMessage from "@/actions/TextMessage.js"
import ActionTextDialog from "@/actions/TextDialog.js"
import ActionTextChoice from "@/actions/TextChoice.js"
import ActionModCoins from "@/actions/ModCoins.js"
import ActionModHealth from "@/actions/ModHealth.js"
import ActionModItemTake from "@/actions/ModItemTake.js"
import ActionModItemDrop from "@/actions/ModItemDrop.js"
import ActionModVariable from "@/actions/ModVariable.js"
import ActionModAutovar from "@/actions/ModAutovar.js"
import ActionIfVariable from "@/actions/IfVariable.js"
import ActionIfAutovar from "@/actions/IfAutovar.js"
import ActionIfItem from "@/actions/IfItem.js"
import ActionIfCoins from "@/actions/IfCoins.js"
import ActionIfHealth from "@/actions/IfHealth.js"
import ActionModForeground from "@/actions/ModForeground.js"
import ActionChangeScene from "@/actions/ChangeScene.js"
import ActionSceneTransition from "@/actions/SceneTransition.js"
import ActionActorMove from "@/actions/ActorMove.js"
import ActionActorTurn from "@/actions/ActorTurn.js"
import ActionGameOver from "@/actions/GameOver.js"
import ActionGameBattle from "@/actions/GameBattle.js"
import ActionGotoRoutine from "@/actions/GotoRoutine.js"
import ActionGotoEvent from "@/actions/GotoEvent.js"
import ActionBattleMessage from "@/actions/BattleMessage.js"
import ActionBattleAnimation from "@/actions/BattleAnimation.js"
import ActionBattleQuit from "@/actions/BattleQuit.js"
import ActionStop from "@/actions/Stop.js"

var L = {
	/// Dimensione cella in pixel
	s: 32,
	s2: 16,
	/// Larghezza dell'area in celle
	w: 0,
	/// Altezza dell'area in celle
	h: 0,
	cursor: {
		mode: "pencil",
		type: "background",
		tile: 0,
		width: 1,
		height: 1,
	},
	history: [],
	pointer: null,
	fgPointer: null,
	id: null,
	actions: {
		ActionTextMessage, ActionTextChoice, ActionTextDialog,
		ActionModCoins, ActionModHealth, ActionModItemDrop, ActionModItemTake,
		ActionModVariable, ActionModAutovar,
		ActionIfVariable, ActionIfAutovar, ActionIfCoins, ActionIfHealth, ActionIfItem,
		ActionGotoRoutine, ActionGotoEvent,
		ActionModForeground, ActionChangeScene, ActionSceneTransition,
		ActionActorMove, ActionActorTurn,
		ActionStop,
		ActionGameOver, ActionGameBattle,
		ActionBattleMessage, ActionBattleAnimation, ActionBattleQuit,
	},
	groupedActions: {},
	data: {
		width: 0,
		height: 0,
		background: null,
		foreground: null,
		actorTiles: null,
		items: [],
		level: 1,
		levels: [true, true, true],
		/// Is current execution editor or game?
		isGame: false,
	},

	/**
	 * Inizializza Logic caricando le immagini
	 * @param Callback da chiamare alla fine
	 * @param Parametri di inizializzazione (scene, isGame)
	 */
	init(callback = null, p = {}) {

		p = Object.assign({
			scene: null,
			isGame: false,
		}, p);

		this.data.scene = p.scene;

		this.w = p.scene.width;
		this.h = p.scene.height;

		// Inizializza le azioni
		if (p.isGame) for (let i in L.actions) if (L.actions[i].init && !L.actions[i]._init) {
			this.actions[i].init();
			this.actions[i]._init = true;
		}

		// Carica le risorse
		Promise.all([
			this.loadImage(`${process.env.VUE_APP_ASSETS}/${this.data.scene.background}.webp`, this.data.scene.background),
			this.loadImage(`${process.env.VUE_APP_ASSETS}/${this.data.scene.foreground}.webp`, this.data.scene.foreground),
			this.loadImage(`${process.env.VUE_APP_ASSETS}/${this.data.scene.actorTiles}.webp`, this.data.scene.actorTiles),
		]).then((images) => {
			this.background = {
				id: images[0].id,
				image: images[0].img,
				w: (images[0].img.width / 64),
				h: (images[0].img.height / 96),
				n: ((images[0].img.width / 64) * (images[0].img.height / 96)),
			};
			this.foreground = {
				id: images[1].id,
				image: images[1].img,
			};
			this.actorTiles = {
				id: images[2].id,
				src: images[2].src,
				image: images[2].img,
				w: (images[2].img.width / 96),
				h: (images[2].img.height / 128),
				n: ((images[2].img.width / 96) * (images[2].img.height / 128)),
				list: [],
			};
			for (let x = 0; x < this.actorTiles.w; x++) for (let y = 0; y < this.actorTiles.h; y++) {
				this.actorTiles.list.push([x, y]);
			}

			if (callback) callback.call();
		});
	},

	pointerDraw() {
		if (["event", "actor", "entrypoint"].indexOf(L.cursor.mode) >= 0) return;

		let m = L.pointer.map;

		if (L.cursor.mode == "eraser") {

			m[L.pointer.y][L.pointer.x].f[this.data.level-1] = null;

		} else if (L.cursor.type == "background") {

			if (L.cursor.mode == "pencil") {
				m[L.pointer.y][L.pointer.x].b = L.cursor.tile;
			} else if (L.cursor.mode == "rectangle") {
				let y1 = Math.min(L.pointer.y_start, L.pointer.y_prev),
					y2 = Math.max(L.pointer.y_start, L.pointer.y_prev),
					x1 = Math.min(L.pointer.x_start, L.pointer.x_prev),
					x2 = Math.max(L.pointer.x_start, L.pointer.x_prev);
				for (let yy = y1; yy <= y2; yy++) for (let xx = x1; xx <= x2; xx++) {
					m[yy][xx].b = this.data.scene.map[yy][xx].b;
				}

				y1 = Math.min(L.pointer.y_start, L.pointer.y),
				y2 = Math.max(L.pointer.y_start, L.pointer.y),
				x1 = Math.min(L.pointer.x_start, L.pointer.x),
				x2 = Math.max(L.pointer.x_start, L.pointer.x);
				for (let yy = y1; yy <= y2; yy++) for (let xx = x1; xx <= x2; xx++) {
					m[yy][xx].b = L.cursor.tile;
				}
			}

		} else if (L.cursor.type == "foreground") {

			let f = this.data.level-1;

			if (L.cursor.mode == "pencil") {
				for (let y = 0; y < L.cursor.height; y++) for (let x = 0; x < L.cursor.width; x++) {
					m[L.pointer.y + y][L.pointer.x + x].f[f] = L.cursor.tile + x + y * 8;
				}
			} else if (L.cursor.mode == "rectangle") {
				let y1 = Math.min(L.pointer.y_start, L.pointer.y_prev),
					y2 = Math.max(L.pointer.y_start, L.pointer.y_prev),
					x1 = Math.min(L.pointer.x_start, L.pointer.x_prev),
					x2 = Math.max(L.pointer.x_start, L.pointer.x_prev);
				for (let yy = y1; yy <= y2; yy++) for (let xx = x1; xx <= x2; xx++) {
					m[yy][xx].f[f] = this.data.scene.map[yy][xx].f[f];
				}

				y1 = Math.min(L.pointer.y_start, L.pointer.y),
				y2 = Math.max(L.pointer.y_start, L.pointer.y),
				x1 = Math.min(L.pointer.x_start, L.pointer.x),
				x2 = Math.max(L.pointer.x_start, L.pointer.x);
				for (let y = 0; y <= y2 - y1; y++) for (let x = 0; x <= x2 - x1; x++) {
					m[y1+y][x1+x].f[f] = L.cursor.tile + (x % L.cursor.width) + (y % L.cursor.height) * 8;
				}
			}
		}

		L.render();
	},

	pointerCommit() {
		this.history.push(this.data.scene.map);
		this.data.scene.map = this.pointer.map;
		this.pointer = null;
	},

	render(editMode = null, clip = null) {
		L.ctx = L.canvas.getContext('2d');
		let map = (L.pointer ? L.pointer.map : this.data.scene.map);
		L.ctx.lineWidth = 1;

		let levels = editMode ? editMode.levels : [true, true, true, false, false];

		let x1, x2, y1, y2;

		if (clip) {
			x1 = clip.x;
			x2 = clip.x + clip.w;
			y1 = clip.y;
			y2 = clip.y + clip.h;
		} else {
			x1 = y1 = 0;
			x2 = L.w;
			y2 = L.h;
		}

		if (!levels[0]) {
			L.ctx.fillStyle = "#ffaaff";
			L.ctx.beginPath();
			L.ctx.rect(0, 0, L.w*L.s, L.h*L.s);
			L.ctx.fill();
		}
		for (let y = y1; y < y2; y++) {
			for (let x = x1; x < x2; x++) {

				if (levels[0]) {
					let m = map[y][x][0];

					let mx = (m % L.background.w) * 64;
					let my = Math.floor(m / L.background.w) * 96;

					let n = L.nears(map, x, y);
					let ox, oy;

					// Top left
					if (n[1] != m && n[7] != m) {
						ox = oy = 0;
					} else if (n[1] == m && n[7] == m) {
						if (n[0] == m) {
							ox = 32;
							oy = 64;
						} else {
							ox = 32;
							oy = 0;
						}
					} else if (n[1] == m && n[7] != m) {
						ox = 0;
						oy = 64;
					} else if (n[1] != m && n[7] == m) {
						ox = 32;
						oy = 32;
					}
					L.ctx.drawImage(L.background.image, mx+ox, my+oy, L.s2, L.s2, x*L.s, y*L.s, L.s2, L.s2);

					// Top right
					if (n[1] != m && n[3] != m) {
						ox = 16;
						oy = 0;
					} else if (n[1] == m && n[3] == m) {
						if (n[2] == m) {
							ox = 16;
							oy = 64;
						} else {
							ox = 48;
							oy = 0;
						}
					} else if (n[1] == m && n[3] != m) {
						ox = 48;
						oy = 64;
					} else if (n[1] != m && n[3] == m) {
						ox = 16;
						oy = 32;
					}
					L.ctx.drawImage(L.background.image, mx+ox, my+oy, L.s2, L.s2, x*L.s+L.s2, y*L.s, L.s2, L.s2);

					// Bottom right
					if (n[3] != m && n[5] != m) {
						ox = oy = 16;
					} else if (n[3] == m && n[5] == m) {
						if (n[4] == m) {
							ox = 16;
							oy = 48;
						} else {
							ox = 48;
							oy = 16;
						}
					} else if (n[3] == m && n[5] != m) {
						ox = 16;
						oy = 80;
					} else if (n[3] != m && n[5] == m) {
						ox = 48;
						oy = 48;
					}
					L.ctx.drawImage(L.background.image, mx+ox, my+oy, L.s2, L.s2, x*L.s+L.s2, y*L.s+L.s2, L.s2, L.s2);

					// Bottom left
					if (n[5] != m && n[7] != m) {
						ox = 0;
						oy = 16;
					} else if (n[5] == m && n[7] == m) {
						if (n[6] == m) {
							ox = 16;
							oy = 48;
						} else {
							ox = 32;
							oy = 16;
						}
					} else if (n[5] == m && n[7] != m) {
						ox = 0;
						oy = 48;
					} else if (n[5] != m && n[7] == m) {
						ox = 32;
						oy = 80;
					}
					L.ctx.drawImage(L.background.image, mx+ox, my+oy, L.s2, L.s2, x*L.s,      y*L.s+L.s2, L.s2, L.s2);
				}

				if (levels[1] && map[y][x][1] !== null) {
					let f = map[y][x][1];
					L.ctx.drawImage(L.foreground.image, ((f % 8) * L.s), (Math.floor(f / 8) * L.s), L.s, L.s, x*L.s, y*L.s, L.s, L.s);
				}

				if (levels[2] && map[y][x][2] !== null) {
					let f = map[y][x][2];
					L.ctx.drawImage(L.foreground.image, ((f % 8) * L.s), (Math.floor(f / 8) * L.s), L.s, L.s, x*L.s, y*L.s, L.s, L.s);
				}

				if (levels[3] && map[y][x][3] !== null) {
					let f = map[y][x][3];
					L.ctx.drawImage(L.foreground.image, ((f % 8) * L.s), (Math.floor(f / 8) * L.s), L.s, L.s, x*L.s, y*L.s, L.s, L.s);
				}
			}
		}

		if (editMode) {
			// Grid
			L.ctx.strokeStyle = "black";
			L.ctx.beginPath();
			for (var y = 0; y < L.h; y++) {
				L.ctx.moveTo(0, y*L.s);
				L.ctx.lineTo(L.w*L.s, y*L.s);
			}
			for (var x = 0; x < L.w; x++) {
				L.ctx.moveTo(x*L.s, 0);
				L.ctx.lineTo(x*L.s, L.h*L.s);
			}
			L.ctx.lineWidth = .5;
			L.ctx.stroke();
		}

		if (levels[4]) {
			this.data.scene.actors.forEach(a => {
				let dir = { down: 0, left: 1, right: 2, up: 3 };
				L.ctx.drawImage(L.actorTiles.image, ((a.tile % L.actorTiles.w) * 3 * L.s), (((Math.floor(a.tile / L.actorTiles.w)) * 4 + dir[a.direction]) * L.s), L.s, L.s, a.x*L.s, a.y*L.s, L.s, L.s);
			});
		}

	},

	renderLevel3(ctx) {

		let x1 = 0, y1 = 0, x2 = L.w, y2 = L.h;
		let map = this.data.scene.map;
		ctx.clearRect(0, 0, L.w*L.s, L.h*L.s);

		for (let y = y1; y < y2; y++) {
			for (let x = x1; x < x2; x++) {
				if (map[y][x][3] !== null) {
					//let f = map[y][x][3];
					//ctx.drawImage(L.foreground.image, ((f % 8) * L.s), (Math.floor(f / 8) * L.s), L.s, L.s, x*L.s, y*L.s, L.s, L.s);
				}
			}
		}

	},

	ctoi(x, y) {
		if (x < 0 || y < 0 || x >= L.w || y >= L.h) {
			return null;
		} else {
			return x + y * L.w;
		}
	},

	nears(map, x, y) {
		return [
			(x > 0 && y > 0         ? map[y-1][x-1] : null),
			(y > 0                  ? map[y-1][x]   : null),
			(x < L.w-1 && y > 0     ? map[y-1][x+1] : null),

			(x < L.w-1              ? map[y][x+1]   : null),

			(x < L.w-1 && y < L.h-1 ? map[y+1][x+1] : null),
			(y < L.h-1              ? map[y+1][x]   : null),
			(x > 0 && y < L.h-1     ? map[y+1][x-1] : null),

			(x > 0                  ? map[y][x-1]   : null),
		].map(a => (a ? a[0] : null));
	},

	nears4f(x, y) {
		return L.nears4fex(x, y, 0, 0, L.w-1, L.h-1);
	},

	/**
	 * Celle adiacenti ad una cella
	 * x, y -> Coordinate cella
	 * x1, y1, x2, y2 -> Dimensione della scena
	 */
	nears4fex(x, y, x1, y1, x2, y2) {
		let a = [];
		if (y > y1) a.push([x, y-1, 0]);
		if (x < x2) a.push([x+1, y, 1]);
		if (y < y2) a.push([x, y+1, 2]);
		if (x > x1) a.push([x-1, y, 3]);
		return a;
	},

	renderSingle(x, y) {
		L.render(false, { x, y, w:1, h:1 });
	},

	/**
	 * Carica un'immagine con una promise
	 */
	loadImage(src, id) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.onload = () => resolve({img, id, src});
			img.onerror = () => reject();
		});
	},

	/**
	 * Genera un UUID-4
	 */
	uuid() {
		return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (a) => (a^Math.random()*16>>a/4).toString(16));
	}

}

Object.keys(L.actions).forEach(a => {
	const g = L.actions[a]._group;
	if (L.groupedActions[g] === undefined) L.groupedActions[g] = [];
	L.groupedActions[g].push(a);
});

export default L;
