import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionModCoins extends Action {
	static _group = Action.GROUP.mod_player;
	static _label = "Modifica Monete";
	static _fields = {
		value: {
			label: "Valore",
			type: "number",
			value: 0
		},
	};

	get abstract() {
		return (this.data.value.value > 0 ? "+" : "") + this.data.value.value + " Monete";
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideText();
			return;
		}

		this.container.modCoins(this.data.value.value);

		this.flag = true;

		if (this.data.value.value >= 0) {
			this.showText(`Hai ricevuto ${this.data.value.value} monete.`);
		} else {
			this.showText(`Hai dato ${-this.data.value.value} monete.`);
		}

		return Runnable.PAUSE;
	}
}

export default ActionModCoins;
