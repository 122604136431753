import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"

const routes = [
	{
		path: "/",
		component: HomeView
	}, {
		path: "/help/:slug?",
		component: () => import( /* webpackChunkName: "help" */ "@/views/HelpView.vue"),
	}, {
		path: "/figure",
		component: () => import("@/views/FigureView.vue"),
	}, {
		path: "/item/:id",
		component: () => import("@/views/Item/SingleView.vue"),
	}, {
		path: "/item",
		component: () => import("@/views/Item/IndexView.vue"),
	}, {
		path: "/asset",
		component: () => import("@/views/Asset/IndexView.vue"),
	}, {
		path: "/asset/new",
		component: () => import("@/views/Asset/CreateView.vue"),
	}, {
		path: "/asset/:id_asset",
		component: () => import("@/views/Asset/SingleView.vue"),
	}, {
		path: "/admin",
		component: () => import( /* webpackChunkName: "admin" */ "@/views/Admin/IndexView.vue"),
	}, {
		path: "/admin/figure",
		component: () => import( /* webpackChunkName: "admin" */ "@/views/Admin/FigureIndex.vue"),
	}, {
		path: "/admin/figure/:id",
		component: () => import( /* webpackChunkName: "admin" */ "@/views/Admin/FigureSingle.vue"),
	}, {
		path: "/admin/page",
		component: () => import( /* webpackChunkName: "admin" */ "@/views/Admin/PageIndex.vue"),
	}, {
		path: "/admin/page/:slug",
		component: () => import( /* webpackChunkName: "admin" */ "@/views/Admin/PageSingle.vue"),
	}, {
		path: "/market",
		component: () => import("@/views/Market/IndexView.vue"),
	}, {
		path: "/play",
		component: () => import("@/views/Play/IndexView.vue"),
	}, {
		path: "/play/:id_story/intro",
		component: () => import("@/views/Play/SingleView.vue"),
	}, {
		path: "/play/:id_story",
		component: () => import("@/views/Play/PlayView.vue"),
	}, {
		path: "/play/:id_story/feedback",
		component: () => import("@/views/Play/FeedbackView.vue"),
	}, {
		path: "/editor",
		component: () => import("@/views/Editor/Story/IndexView.vue"),
	}, {
		path: "/editor/:id_story",
		component: () => import("@/views/Editor/Story/SingleView.vue"),
	}, {
		path: "/editor/:id_story/variable",
		component: () => import("@/views/Editor/Variable/IndexView.vue"),
	}, {
		path: "/editor/:id_story/variable/new",
		component: () => import("@/views/Editor/Variable/CreateView.vue"),
	}, {
		path: "/editor/:id_story/variable/:id_variable",
		component: () => import("@/views/Editor/Variable/SingleView.vue"),
	}, {
		path: "/editor/:id_story/scene",
		component: () => import("@/views/Editor/Scene/IndexView.vue"),
	}, {
		path: "/editor/:id_story/scene/:id_scene",
		component: () => import("@/views/Editor/Scene/SingleView.vue"),
	}, {
		path: "/editor/:id_story/scene/:id_scene/edit",
		component: () => import("@/views/Editor/Scene/EditView.vue"),
	}, {
		path: "/editor/:id_story/scene/:id_scene/routine",
		component: () => import("@/views/Editor/Routine/IndexView.vue"),
	}, {
		path: "/editor/:id_story/scene/:id_scene/routine/:id_routine",
		component: () => import("@/views/Editor/Routine/SingleView.vue"),
	}, {
		path: "/:pathMatch(.*)*",
		component: () => import("@/views/404View.vue"),
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
