import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionActorMove extends Action {
	static _group = Action.GROUP.mod_actor;
	static _label = "Sposta Attore";
	static _fields = {
		actor: {
			label: "Attore",
			type: "select",
			value: "",
			options: {},
		},
		position: {
			label: "Position",
			type: "text",
			value: "0,0"
		},
	};

	get fields() {
		let options = {};
		this.container.scene.actors.forEach(a => { options[a.uuid] = a.name });
		return Object.assign(this.constructor._fields, { actor: Object.assign(this.constructor._fields.routine, { options }) });
	}

	get abstract() {
		return this.container.scene.getActor(this.data.actor).name+" moves to "+this.data.position;
	}

	action() {
		if (!this.flag) {
			this.actor = this.container.scene.getActor(this.data.actor.value);

			// Path build
			let pos = this.data.position.value.split(",").map(a => parseInt(a));
			if (pos[0] != this.actor.x || pos[1] != this.actor.y) {
				this.path = this.container.buildPath(pos[0], pos[1], this.actor.x, this.actor.y);
				this.flag = true;
			} else {
				return;
			}
		}

		if (this.path.length) {
			this.container.sprite.actor = this.actor;
			switch (this.path.shift()) {
				case 0:
					this.container.sprite.actor.y--;
					this.container.sprite.dy = this.container.s;
					this.actor.direction = "up";
					break;
				case 1:
					this.container.sprite.actor.x++;
					this.container.sprite.dx = -this.container.s;
					this.actor.direction = "right";
					break;
				case 2:
					this.container.sprite.actor.y++;
					this.container.sprite.dy = -this.container.s;
					this.actor.direction = "down";
					break;
				case 3:
					this.container.sprite.actor.x--;
					this.container.sprite.dx = this.container.s;
					this.actor.direction = "left";
					break;
			}
			return Runnable.CONTINUE;
		} else {
			this.flag = false;
		}

	}
}

export default ActionActorMove;
