import L from "@/Logic.js"

class Value {
	constructor(value, field) {
		this.field = field;
		this.value = (typeof value === "object" ? value.value : ( value || field.value));
	}

	get label() { return this.field.label }
	get type() { return this.field.type }
	get options() { return this.field.options }

	toJSON() {
		return this.value;
	}
}

class Action {

	static _fields = {};
	static _size = null;

	static GROUP = {
		interaction: "Interazione",
		mod_player: "Modifica giocatore",
		mod_actor: "Modifica attore",
		flow_control: "Controllo esecuzione",
		scene_control: "Controllo scena",
		game_control: "Controllo gioco",
		battle: "Battaglia",
	}

	static _operators = {
		assign:   "=",
		plus:     "+",
		minus:    "-",
		multiply: "×",
		divide:   "÷",
		modulo:   "MOD",
		cancat:   "~",
	};

	static _operatorsEx = {
		assign:   "= Assegnamento",
		plus:     "+ Addizione",
		minus:    "- Sottrazione",
		multiply: "× Moltiplicazione",
		divide:   "÷ Divisione",
		modulo:   "MOD Modulo",
		cancat:   "~ Concatenazione",
	};

	static _comparisons = {
		eq: "=",
		ne: "≠",
		gt: ">",
		ge: "≥",
		lt: "<",
		le: "≤",
	};

	static _comparisonsEx = {
		eq: "= Uguale",
		ne: "≠ Diverso",
		gt: "> Maggiore",
		ge: "≥ Maggiore o uguale",
		lt: "< Minore",
		le: "≤ Minore o uguale"
	};

	constructor(container, d = {}) {
		this.uuid = (d.u || L.uuid());
		this.indent = (d.i || 0);
		this.data = {};
		this.container = container; // contenitore dello stato (Store o Game)

		let data = (d.data || d.v || {}); // clone, load, new

		let fields = this.constructor._fields;
		Object.keys(fields).forEach(f => {
			this.data[f] = new Value(data[f], fields[f]);
		});
	}

	static factory(container, d) {
		let t;
		if (typeof d === "string") {
			t = d;
			d = {};
		} else {
			t = (d.t || d.constructor.name);
		}
		return L.actions[t] ? new L.actions[t](container, d) : null;
	}

	clone() {
		let clone = Action.factory(this.container, this.constructor.name);
		clone.uuid = this.uuid;
		clone.ident = this.indent;
		for (let k in this.constructor._fields) clone.data[k] = new Value(this.data[k], this.constructor._fields[k]);
		return clone;
	}

	get type() { return this.constructor.name }
	get group() { return this.constructor._group }
	get label() { return this.constructor._label }
	get fields() { return this.constructor._fields }

	get abstract() { return ''; }

	submit(form) {
		let fields = this.constructor._fields;
		Object.keys(fields).forEach(a => {
			if (fields[a].type === "text" || fields[a].type === "color") {
				this.data[a].value = form[a].value;
			} else if (fields[a].type === "number") {
				this.data[a].value = parseInt(form[a].value);
			} else if (fields[a].type === "select") {
				this.data[a].value = form[a].options[form[a].selectedIndex].value;
			}
		});
	}

	toJSON() {
		return {
			t: this.constructor.name,
			i: this.indent,
			u: this.uuid,
			v: this.data,
		}
	}

	// ### Utilities

	showText(text) {
		let lines = this.container.measureText(text, "text").lines;
		if (!lines.length) return false;

		if (this.constructor._size !== this.container.size) {
			if (this.constructor.textBox) this.textBox.destroy();
			this.constructor.textBox = this.container.createTextBox();
			this.container.land.overlay.addChild(this.constructor.textBox);
			this.constructor._size = this.container.size;
		}

		this.constructor.textBox.visible = true;
		this.constructor.textBox.children[0].text = text;

		return true;
	}

	hideText() {
		this.constructor.textBox.visible = false;
		return false;
	}

	static resize(container) {
		if (this.dialogBox) this.dialogBox.destroy();
		this.dialogBox = container.createDialogBox();
		container.land.overlay.addChild(this.dialogBox);
		this._size = container.width+"x"+container.height;
	}

	showDialog(text) {
		let lines = this.container.measureText(text, "text").lines;
		if (!lines.length) return false;

		if (this.constructor._size !== this.container.size) {
			if (this.constructor.dialogBox) this.dialogBox.destroy();
			this.constructor.dialogBox = this.container.createDialogBox();
			this.container.land.overlay.addChild(this.constructor.dialogBox);
			this.constructor._size = this.container.size;
		}

		this.constructor.dialogBox.visible = true;
		this.constructor.dialogBox.children[0].text = text;

		return true;
	}

	hideDialog() {
		this.constructor.dialogBox.visible = false;
	}


}

export default Action;
