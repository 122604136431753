class Figure {
	constructor(d) {
		this.id_member = d.id_member;
		this.name = d.name;
		this.data = d.data;

		/*
		this.hash = d.hash;
		this.feedback = d.feedback;
		this.bio = (d.bio || null);

		this.avatar_url = (this.hash ? 'https://www.gravatar.com/avatar/'+this.hash+'?s=48&d=identicon' : "/img/mistery-man.jpg");
		this.avatar160_url = (this.hash ? 'https://www.gravatar.com/avatar/'+this.hash+'?s=160&d=identicon' : "/img/mistery-man.jpg");
		this.url = "/profile/"+this.id_user;
		*/
	}
}

export default Figure;
