import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionIfHealth extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Condizione Salute";
	static _fields = {
		comparison: {
			label: "Comparatore",
			type: "select",
			value: "eq",
			options: ActionIfHealth._comparisonsEx
		},
		value: {
			label: "Valore",
			type: "number",
			value: 0
		},
	};

	get abstract() {
		return "Salute "
			+ ActionIfHealth._comparisons[this.data.comparison.value] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		const v = this.container.figure.data.health;
		let f = false;
		switch (this.data.comparison.value) {
			case "eq":
				f = (v == this.data.value.value);
				break;
			case "ne":
				f = (v != this.data.value.value);
				break;
			case "gt":
				f = (v > this.data.value.value);
				break;
			case "ge":
				f = (v >= this.data.value.value);
				break;
			case "lt":
				f = (v < this.data.value.value);
				break;
			case "le":
				f = (v <= this.data.value.value);
				break;
		}
		return (f ? null : Runnable.JUMP);
	}
}

export default ActionIfHealth;
