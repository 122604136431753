import L from "@/Logic.js"

import Runnable from "@/classes/Runnable.js"

class Routine extends Runnable {
	constructor(container, d = {}) {
		super(container, d);
		this.uuid = (d.uuid || L.uuid());
		this.name = (d.name || "Nuova Routine");
		this.load = (d.load || false);
	}

	clone() {
		return new Routine(this);
	}

	toJSON() {
		return {
			list: this.list,
			uuid: this.uuid,
			name: this.name,
			load: this.load,
		}
	}

}

export default Routine;
