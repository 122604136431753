import Action from "@/classes/Action.js"

class ActionGotoEvent extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Esegui Evento";
	static _fields = {
		event: {
			label: "Event",
			type: "select",
			value: "",
			options: {},
		},
	};

	get fields() {
		let options = {};
		this.container.scene.events.forEach(a => { options[a.uuid] = a.name });
		return Object.assign(this.constructor._fields, { event: Object.assign(this.constructor._fields.event, { options }) });
	}

	get abstract() {
		return this.container.scene.getEvent(this.data.event.value).name;
	}

	// ###

	action() {
		this.container.scene.getEvent(this.data.event.value).run();
	}
}

export default ActionGotoEvent;
