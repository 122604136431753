import Action from "@/classes/Action.js"

import G from "@/Game.js"

class ActionGameBattle extends Action {
	static _group = Action.GROUP.game_control;
	static _label = "Battle*";
	static _fields = {};

	get abstract() {
		return "Battle";
	}

	action() {
		G.gameBattleStart();
	}
}

export default ActionGameBattle;
