import L from "@/Logic.js"

class Variable {
	constructor(d = {}) {
		this.uuid = (d.uuid || L.uuid());
		this.name = (d.name || "Nuova Variabile");
		this.type = (d.type || "text"); // text, number, boolean
		this.initial = (d.initial === undefined ? "" : d.initial);
		this.value = this.initial;
	}
}

export default Variable;
