import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

import * as PIXI from "pixi.js"

class ActionModItemDrop extends Action {
	static _group = Action.GROUP.mod_player;
	static _label = "Prendi oggetto al personaggio";
	static _fields = {
		item: {
			label: "Oggetto",
			type: "select",
			value: "",
			options: []
		},
	};

	get fields() {
		let options = {};
		Object.values(this.container.items).forEach(a => options[a.id_item] = a.name);
		return Object.assign(this.constructor._fields, { item: Object.assign(this.constructor._fields.item, { options }) });
	}

	get abstract() {
		if (!this.data.item.value) return "∅";
		return this.container.items[this.data.item.value].name;
	}

	// ###

	action() {
		if (this.flag) {
			this.showText(`Hai dato l'oggetto.`);
			this.flag = this.hideText();
			return;
		}

		if (!this.constructor.selectBox) {
			this.constructor.selectBox = new PIXI.Graphics();
		} else {
			this.constructor.selectBox.clear();
			this.constructor.selectBox.removeChildren();
		}

		if (!this.constructor.cursor) {
			this.constructor.cursor = new PIXI.Graphics();
			this.constructor.cursor.beginFill(0xFF3300);
			this.constructor.cursor.drawCircle(0, 0, 8);
			this.constructor.cursor.endFill();
			this.constructor.cursor.position.set(16, 16);
		}

		this.constructor.selectBox.addChild(this.constructor.cursor);

		this.choice = 0;
		this.choices = [];

		let items = this.container.figure.data.items.filter(a => a.u === this.data.item.value);

		// Non possiede l'oggetto
		if (items.length === 0) return;

		items.forEach((a, i) => {
			this.choices.push(a.u);
			let text = new PIXI.Text(this.container.items[a.i], this.container.textBoxStyle);
			text.position.set(30, 8 + 22 * i);
			this.constructor.selectBox.addChild(text);
		});

		let boxHeight = this.choices.length * 22 + 16;
		this.constructor.selectBox.beginFill(this.container.backgroundColor)
			.drawRoundedRect(0, 0, 260, boxHeight, 16)
			.endFill();

		this.constructor.selectBox.position.set(this.container.width * this.container.s - 268, this.container.height * this.container.s - 16 - boxHeight);
		this.container.land.overlay.addChild(this.constructor.selectBox);

		this.flag = true;

		return Runnable.PAUSE;

	}

	state() {
		if (this.container.keys.ArrowUp == 1) {
			if (--this.choice < 0) this.choice += this.choices.length;
		} else if (this.container.keys.ArrowDown == 1) {
			if (++this.choice == this.choices.length) this.choice -= this.choices.length;
		} else return;

		this.constructor.cursor.position.set(16, 16 + this.choice * 22);
	}
}

export default ActionModItemDrop;
