import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

import G from "@/Game.js"
import * as PIXI from "pixi.js"

class ActionBattleAnimation extends Action {
	static _group = Action.GROUP.scene_control;
	static _label = "Battle Transition*";
	static _fields = {
		effect: { label: "Effect", type: "select", value: "fade", options: {
			fade: "Fade",
			slide: "Slide",
			circle: "Circle",
			star: "Star",
			heart: "Heart",
		}},
		mode: { label: "Mode", type: "select", value: "in", options: { in: "In", out: "Out" } },
		direction: { label: "Direction", type: "select", value: "right", options: { up: "Up", right: "Right", down: "Down", left: "Left" } },
		color: { label: "Color", type: "color", value: "#ffffff" },
	};

	static init() {
		ActionBattleAnimation.canvas = new PIXI.Graphics();
	}

	get abstract() {
		let f = ActionBattleAnimation.fields;
		return "Scene Transition "+f.effect.options[this.data.effect]+" "+f.direction.options[this.data.direction]+" "+f.mode.options[this.data.mode];
	}

	action() {

		if (!this.flag) {

			let canvas = ActionBattleAnimation.canvas;
			canvas.clear();
			canvas.position.set(0);
			canvas.alpha = 1;
			canvas.beginFill(parseInt(this.data.color.substr(1), 16));

			let centered = false;
			let w, w2, h, h2, h4;

			switch (this.data.effect) {
				case "fade":
					canvas.drawRect(0, 0, G.width*this.G.s, G.height*this.G.s);
					break;
				case "slide":
					canvas.drawRect(0, 0, G.width*this.G.s, G.height*this.G.s);
					if (this.data.direction == "up") this.max = G.height*this.G.s;
					else if (this.data.direction == "down") this.max = G.height*this.G.s;
					else if (this.data.direction == "left") this.max = G.width*this.G.s;
					else if (this.data.direction == "right") this.max = G.width*this.G.s;
					break;
				case "circle":
					canvas.drawCircle(G.width*this.G.s/2, G.height*this.G.s/2, G.height*this.G.s/2);
					centered = true;
					break;
				case "star":
					canvas.drawStar(G.width*this.G.s/2, G.height*this.G.s/2, 5, G.height*this.G.s/2, G.height*this.G.s*.3);
					centered = true;
					break;
				case "heart":
					w = this.G.width*this.G.s;
					w2 = w/2;
					h = this.G.height*this.G.s;
					h2 = h/2;
					h4 = h/4;
					canvas.moveTo(w2, h)
						.bezierCurveTo(w2+h, h2, w2+h4, -h4, w2, h4)
						.bezierCurveTo(w2-h4, -h4, w2-h, h2, w2, h)
						.closePath();
					centered = true;
					break;
			}

			if (ActionBattleAnimation.sprite) ActionBattleAnimation.sprite.destroy();

			ActionBattleAnimation.sprite = new PIXI.Sprite(G.app.renderer.generateTexture(canvas));
			G.stage.overlay.addChild(ActionBattleAnimation.sprite);

			if (centered) {
				ActionBattleAnimation.sprite.position.set(G.width*this.G.s/2, G.height*this.G.s/2);
				ActionBattleAnimation.sprite.anchor.set(.5);
			}

			this.count = 51;
			this.flag = true;
		}

		if (--this.count == -1) {
			if (this.data.mode == "in") {
				ActionBattleAnimation.sprite.destroy();
				delete ActionBattleAnimation.sprite;
			}
			delete this.flag;
			return;
		}

		let sprite = ActionBattleAnimation.sprite;
		switch (this.data.effect) {
			case "fade":
				if (this.data.mode == "in") {
					sprite.alpha = this.count / 50;
				} else if (this.data.mode == "out") {
					sprite.alpha = (50 - this.count) / 50;
				}
				break;
			case "slide":
				if (this.data.mode == "out") {
					if (this.data.direction == "up" || this.data.direction == "down") {
						sprite.y = this.max * this.count / 50;
					} else if (this.data.direction == "left" || this.data.direction == "right") {
						sprite.x = this.max * this.count / 50;
					}
				} else if (this.data.mode == "in") {
					if (this.data.direction == "up" || this.data.direction == "down") {
						sprite.y = this.max * (50 - this.count) / 50;
					} else if (this.data.direction == "left" || this.data.direction == "right") {
						sprite.x = this.max * (50 - this.count) / 50;
					}
				}
				break;
			case "circle":
			case "star":
			case "heart":
				if (this.data.mode == "in") {
					sprite.scale.set(3 * this.count / 50);
				} else if (this.data.mode == "out") {
					sprite.scale.set(3 * (50 - this.count) / 50);
				}
				break;
		}

		return Runnable.CONTINUE;
	}
}

export default ActionBattleAnimation;
