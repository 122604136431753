import Action from "@/classes/Action.js"

class ActionGotoRoutine extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Esegui Routine";
	static _fields = {
		routine: {
			label: "Routine",
			type: "select",
			value: "",
			options: {},
		},
	};

	get fields() {
		let options = {};
		this.container.scene.routines.forEach(a => options[a.uuid] = a.name);
		return Object.assign(this.constructor._fields, { routine: Object.assign(this.constructor._fields.routine, { options }) });
	}

	get abstract() {
		if (!this.data.routine.value) return "∅";

		return this.container.scene.getRoutine(this.data.routine.value).name;
	}

	// ###

	action() {
		this.container.scene.getRoutine(this.data.routine.value).run();
	}
}

export default ActionGotoRoutine;
