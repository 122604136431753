import Action from "@/classes/Action.js"

class ActionModAutovar extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Modifica Auto Variabile";

	static _fields = {
		autovar: {
			label: "Auto Variable",
			type: "select",
			value: "a",
			options: { a: "A", b: "B", c: "C", d: "D" }
		},
		operator: {
			label: "Operatore",
			type: "select",
			value: "assign",
			options: ActionModAutovar._operatorsEx
		},
		value: {
			label: "Valore",
			type: "text",
			value: 0
		},
	};

	get abstract() {
		return this.data.autovar.value.toUpperCase() + " "
			+ ActionModAutovar._operators[this.data.operator.value] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		switch (this.data.operator.value) {
			case "equal":
				this.container.scene.autovar[this.data.autovar.value] = this.data.value.value;
				break;
			case "plus":
				this.container.scene.autovar[this.data.autovar.value] += parseInt(this.data.value.value);
				break;
			case "minus":
				this.container.scene.autovar[this.data.autovar.value] -= this.data.value.value;
				break;
			case "multiply":
				this.container.scene.autovar[this.data.autovar.value] *= this.data.value.value;
				break;
			case "divide":
				this.container.scene.autovar[this.data.autovar.value] /= this.data.value.value;
				break;
			case "modulo":
				this.container.scene.autovar[this.data.autovar.value] %= this.data.value.value;
				break;
			case "concat":
				this.container.scene.autovar[this.data.autovar.value] += String(this.data.value.value);
				break;
		}
	}
}

export default ActionModAutovar;
