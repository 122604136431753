import Action from "@/classes/Action.js"

class ActionChangeScene extends Action {
	static _group = Action.GROUP.scene_control;
	static _label = "Cambia Scena";
	static _fields = {
		entrypoint: {
			label: "Punto di ingresso Scena",
			type: "select",
			subtype: "entrypoint",
			value: null,
			options: [],
		},
	}

	get fields() {
		let options = {};
		this.container.entrypoints.forEach(a => options[a.uuid] = a.name);
		return { entrypoint: Object.assign(this.constructor._fields.entrypoint, { options }), };
	}

	get abstract() {
		if (this.data.entrypoint.value) {
			let ep = this.container.entrypoints.find(a => a.uuid == this.data.entrypoint.value);
			return ep.name;
		} else {
			return "∅";
		}
	}

	// ###

	action() {
		this.container.loadScene(...this.data.entrypoint.value.split(":"));
	}
}

export default ActionChangeScene;
