import Action from "@/classes/Action.js"

class ActionActorTurn extends Action {
	static _group = Action.GROUP.mod_actor;
	static _label = "Ruota Attore*";
	static _fields = {
		actor: {
			label: "Attore",
			type: "select",
			value: "",
			options: {},
		},
		direction: {
			label: "Position",
			type: "select",
			value: "right",
			options: { up: "Alto", right: "Destra", down: "Basso", left: "Sinistra", player: "Verso il personaggio" },
		},
	};

	get fields() {
		let options = {};
		this.container.scene.actors.forEach(a => options[a.uuid] = a.name);
		return Object.assign(this.constructor._fields, { actor: Object.assign(this.constructor._fields.routine, { options }) });
	}

	get abstract() {
		return this.container.scene.getActor(this.data.actor.value).name
			+ " si gira a "
			+ ActionActorTurn._fields.direction.options[this.data.direction.value];
	}

	action() {
		let actor = this.container.scene.getActor(this.data.actor.value);

		if (this.data.direction.value == "player") {
			let x = this.container.figure.x - actor.x;
			let y = this.container.figure.y - actor.y;
			if (Math.abs(x) > Math.abs(y)) {
				actor.direction = (x < 0 ? "left" : "right");
			} else {
				actor.direction = (y < 0 ? "up" : "down");
			}
		} else {
			actor.direction = this.data.direction.value;
		}

		this.G.sprite.actor = actor;
	}
}

export default ActionActorTurn;
