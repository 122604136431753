import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionModItemTake extends Action {
	static _group = Action.GROUP.mod_player;
	static _label = "Dai Oggetto al personaggio";
	static _fields = {
		item: {
			label: "Oggetto",
			type: "select",
			value: "",
			options: {}
		},
		value: {
			label: "Valore",
			type: "number",
			value: 1,
			min: 1,
		},
	};

	get fields() {
		let options = {};
		Object.values(this.container.items).forEach(a => options[a.id_item] = a.name);
		return Object.assign(this.constructor._fields, { item: Object.assign(this.constructor._fields.item, { options }) });
	}

	get abstract() {
		if (!this.data.item.value) return "∅";
		return (this.data.value.value > 0 ? "+" : "") + this.data.value.value + " "
			+ this.container.items[this.data.item.value].name
		;
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideText();
			return;
		}

		this.flag = true;
		this.container.modItem(this.data.item, this.data.value.value);

		let item = this.container.items[this.data.value.value];
		this.showText(`Hai ricevuto ${this.data.value.value} ${item.name}.`);

		return Runnable.PAUSE;

	}
}

export default ActionModItemTake;
