import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

import G from "@/Game.js"

class ActionBattleMessage extends Action {
	static _group = Action.GROUP.battle;
	static _label = "Show Message*";
	static _fields = {
		text: { label: "Display text", type: "text", value: "" },
	};

	static resize() {
		if (this.textBox) this.textBox.destroy();
		this.textBox = G.createTextBox();
		G.battle.stage.addChild(this.textBox);
		this._size = G.width+"x"+G.height;
	}

	action() {

		let self = this.constructor;

		if (this.flag) {
			this.flag = false;
			self.textBox.visible = false;
			return;
		}

		this.lines = G.measureText(this.data.text, "dialog").lines;

		if (this.lines.length) {

			if (self._size != G.width+"x"+G.height) self.resize();

			self.textBox.visible = true;
			self.textBox.children[0].text = this.data.text;
			this.flag = true;
			return Runnable.PAUSE;
		}
	}
}

export default ActionBattleMessage;
