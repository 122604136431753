import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

class ActionGameOver extends Action {
	static _group = Action.GROUP.game_control;
	static _label = "Game Over";
	static _fields = {
		result: {
			label: "Risultato",
			type: "select",
			value: "win",
			options: {
				win: "Vittoria",
				fail: "Sconfitta",
			}
		},
	};

	get abstract() {
		return "Game Over per "+this.fields.result.options[this.data.result.value];
	}

	// ###

	action() {
		if (this.flag) {
			this.flag = this.hideText();
			this.container.gameOver(this.data.result.value);
			return;
		}

		this.flag = this.showText(this.fields.result.options[this.data.result.value]);

		return Runnable.PAUSE;
	}
}

export default ActionGameOver;
