import L from "@/Logic.js"

import Entrypoint from "@/classes/Entrypoint.js"
import Actor from "@/classes/Actor.js"
import Routine from "@/classes/Routine.js"
import RoutineEx from "@/classes/RoutineEx.js"

class Scene {

	static CELL_BACKGROUND = 0;
	static CELL_FOREGROUND = 1;
	static CELL_OVERFLOW = 1;

	constructor(container, d = {}) {

		this.id_scene = (d.id_scene || 0);
		this.name = (d.name || "Nuova scena");
		this.container = container;

		let dd = (d.data || d);

		this.width = (dd.width || 20);
		this.height = (dd.height || 20);
		this.last_x = this.width-1;
		this.last_y = this.height-1;
		this.background = (dd.background || null);
		this.foreground = (dd.foreground || null);
		this.actorTiles = (dd.actorTiles || dd.actor || null);
		this.entrypoints = (dd.entrypoints || []).map(a => new Entrypoint(a));
		this.entrypoint = (dd.entrypoint || null);
		this.actors = (dd.actors || []).map(a => new Actor(a));
		this.routines = (dd.routines || []).map(a => new Routine(container, a));
		this.events = (dd.events || []).map(a => new RoutineEx(container, a));
		this.cover = (dd.cover || false);
		this.playlist = (dd.playlist || null);

		this.autovar = { a: null, b: null, c: null, d: null };

		if (!dd.map) dd.map = [];
		this.map = [];

		if (dd.map.length === this.width * this.height) {
			for (let y = 0, i = 0; y < this.height; y++) {
				this.map[y] = [];
				for (let x = 0; x < this.width; x++, i++) {
					if (dd.map[i] instanceof Array) {
						this.map[y][x] = dd.map[i];
					} else if (typeof dd.map[i] === "number") {
						this.map[y][x] = [ dd.map[i], null, null, null ];
					} else {
						this.map[y][x] = [ 0, null, null, null ];
					}
				}
			}
		} else {
			for (let y = 0; y < this.height; y++) {
				this.map[y] = [];
				for (let x = 0; x < this.width; x++) {
					if (dd.map[y] !== undefined && dd.map[y][x] !== undefined) {
						this.map[y][x] = dd.map[y][x].map(a => a);
					} else {
						this.map[y][x] = [ 0, null, null, null ];
					}
				}
			}
		}

	}

	changeWidth(w) {
		w = parseInt(w);
		if (w < this.width) {
			this.map.forEach(a => a.splice(w));
		} else if (w > this.width) {
			let d = w - this.width;
			this.map.forEach(a => a.push(...new Array(d).fill({ b: 0, o: 0 })));
		}
		this.width = w;
	}

	changeHeight(h) {
		h = parseInt(h);
		if (h < this.height) {
			this.map.splice(h);
		} else if (h > this.height) {
			for (let i = 0; i < (h - L.h); i++) this.map.push(new Array(L.w).fill({ b: 0, o: 0 }));
		}
		this.height = h;
	}

	cloneMap() {
		let clone = [];
		for (let y = 0; y < L.h; y++) {
			clone[y] = []
			for (let x = 0; x < L.w; x++) {
				clone[y][x] = {
					b: this.map[y][x].b,
					f: [this.map[y][x].f[0], this.map[y][x].f[1], this.map[y][x].f[2]],
				};
			}
		}
		return clone;
	}

	// ### Events

	addEvent(x, y) {
		let rt = new RoutineEx(this.container, { x, y });
		this.events.push(rt);
		return rt;
	}

	getEvent(uuid) {
		return this.events.find(a => (a.uuid == uuid));
	}

	findEvent(x, y) {
		return this.events.find(a => (a.x == x && a.y == y));
	}

	replaceEvent(item) {
		this.events = this.events.map(a => (a.uuid === item.uuid ? item : a));
	}

	appendEvent(item) {
		this.events.push(item);
	}

	removeEvent(item) {
		this.events = this.events.filter(a => a.uuid != item.uuid);
	}

	delEvent(uuid) {
		this.events = this.events.filter(a => a.uuid != uuid);
	}

	// ### Entrypoints

	getEntrypoint(uuid) {
		return this.entrypoints.find(a => (a.uuid == uuid));
	}

	findEntrypoint(x, y) {
		return this.entrypoints.find(a => (a.x === x && a.y === y));
	}

	addEntrypoint(x, y) {
		let ep = new Entrypoint({ x, y });
		this.entrypoints.push(ep);
		return ep;
	}

	replaceEntrypoint(item) {
		this.entrypoints = this.entrypoints.map(a => (a.uuid === item.uuid ? item : a));
	}

	appendEntrypoint(item) {
		this.entrypoints.push(item);
	}

	removeEntrypoint(item) {
		this.entrypoints = this.entrypoints.filter(a => a.uuid === item.uuid);
	}

	delEntrypoint(x, y) {
		this.entrypoints = this.entrypoints.filter(a => (a.x != x || a.y != y));
	}

	// ### Actors

	getActor(uuid) {
		return this.actors.find(a => (a.uuid == uuid));
	}

	findActor(x, y) {
		return this.actors.find(a => (a.x == x && a.y == y));
	}

	addActor(x, y) {
		let ac = new Actor({ x, y });
		this.actors.push(ac);
		return ac;
	}

	replaceActor(item) {
		let i = this.actors.findIndex(a => a.uuid === item.uuid);
		if (i > -1) this.actors[i] = item;
	}

	appendActor(item) {
		this.actors.push(item);
	}

	removeActor(item) {
		this.actors = this.actors.filter(a => a.uuid != item.uuid);
	}

	delActor(uuid) {
		this.actors = this.actors.filter(a => a.uuid != uuid);
	}

	// ### Routines

	addRoutine() {
		let rt = new Routine();
		this.routines.push(rt);
		return rt;
	}

	getRoutine(uuid) {
		return this.routines.find(a => (a.uuid == uuid));
	}

	replaceRoutine(item) {
		this.routines = this.routines.map(a => (a.uuid === item.uuid ? item : a));
	}

	delRoutine(uuid) {
		this.routines = this.routines.filter(a => a.uuid != uuid);
	}

	// ###

	mapExport() {
		let map = [];
		this.map.forEach(y => y.forEach(x => map.push((x[1] === null && x[2] === null && x[3] === null) ? x[0] : x)));
		return map;
	}

	toJSON() {
		return {
			id_scene: this.id_scene,
			name: this.name,
			data: {
				width: this.width,
				height: this.height,
				background: this.background,
				foreground: this.foreground,
				actorTiles: this.actorTiles,
				entrypoints: this.entrypoints,
				entrypoint: this.entrypoint,
				actors: this.actors,
				routines: this.routines,
				events: this.events,
				cover: this.cover,
				map: this.mapExport(),
			}
		}
	}
}

export default Scene;
