import Action from "@/classes/Action.js"

class ActionModVariable extends Action {
	static _group = Action.GROUP.flow_control;
	static _label = "Modifica Variabile";

	static _fields = {
		variable: {
			label: "Variabile",
			type: "select",
			value: "",
			options: {},
		},
		operator: {
			label: "Operatore",
			type: "select",
			value: "assign",
			options: ActionModVariable._operators
		},
		value: {
			label: "Valore",
			type: "text",
			value: 0
		},
	};

	get fields() {
		let options = {};
		this.container.story.variables.forEach(a => options[a.uuid] = a.name);
		return Object.assign(this.constructor._fields, { variable: Object.assign(this.constructor._fields.variable, { options }) });
	}

	get abstract() {
		if (!this.data.variable.value) return "∅";
		return this.container.story.getVariable(this.data.variable.value).name + " "
			+ ActionModVariable._operators[this.data.operator.value] + " "
			+ this.data.value.value
		;
	}

	// ###

	action() {
		let v = this.container.story.getVariable(this.data.variable.value);

		switch (this.data.operator.value) {
			case "assign":
				v.value = this.data.value.value;
				break;
			case "plus":
				v.value += parseInt(this.data.value.value);
				break;
			case "minus":
				v.value -= this.data.value.value;
				break;
			case "multiply":
				v.value *= this.data.value.value;
				break;
			case "divide":
				v.value /= this.data.value.value;
				break;
			case "modulo":
				v.value %= this.data.value.value;
				break;
			case "concat":
				v.value += String(this.data.value.value);
				break;
		}
	}
}

export default ActionModVariable;
