<template>
	<nav>
		<router-link v-if="back" :to="back" class="mdi mdi-arrow-left"></router-link>
		<router-link v-else-if="title" to="/" class="mdi mdi-home-outline"></router-link>
		<span v-if="title">{{ title }}</span>
		<span class="spacer"></span>
		<span><i class="mdi mdi-sword-cross"></i> Guro Land</span>
	</nav>
</template>

<style lang="scss">
nav {
	position: fixed;
	width: 100%;
	top: 0;
	margin: 0;
	background: var(--lucid);
	padding: 0 1rem 0 0;
	line-height: 2.8rem;
	height: 3rem;
	box-sizing: border-box;
	border-bottom: 1px solid var(--border-color);
	display: flex;
	.mdi {
		font-size: 1.25rem;
		text-align: center;
		width: 3rem;
		vertical-align: middle;
	}
}
</style>

<script>
export default {
	props: ['title', 'back'],
}
</script>
