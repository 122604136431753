import Action from "@/classes/Action.js"
import Runnable from "@/classes/Runnable.js"

import * as PIXI from "pixi.js"

class ActionTextChoice extends Action {
	static _group = Action.GROUP.interaction;
	static _label = "Domanda";
	static _fields = {
		text: {
			label: "Testo della domanda",
			type: "text",
			value: ""
		},
		variable: {
			label: "Variabile",
			type: "select",
			value: "",
			options: {},
		},
		answer_0: { label: "Risposta A", type: "text", value: "" },
		value_0:  { label: "Valore A",   type: "text", value: "" },
		answer_1: { label: "Risposta B", type: "text", value: "" },
		value_1:  { label: "Valore B",   type: "text", value: "" },
		answer_2: { label: "Risposta C", type: "text", value: "" },
		value_2:  { label: "Valore C",   type: "text", value: "" },
		answer_3: { label: "Risposta D", type: "text", value: "" },
		value_3:  { label: "Valore D",   type: "text", value: "" },
	};

	get fields() {
		let options = { a: "Auto A", b: "Auto B", c: "Auto C", d: "Auto D" };
		this.container.story.variables.forEach(a => options[a.uuid] = a.name);

		return Object.assign(this.constructor._fields, { variable: Object.assign(this.constructor._fields.variable, { options }) });
	}

	get abstract() {
		if (this.data.text.value.length === 0) return "∅";

		if (this.data.text.value.length > 40) {
			return this.data.text.value.substr(0, 40)+"…";
		} else {
			return this.data.text.value;
		}
	}

	// ###

	action() {
		if (this.flag) {

			if (this.data.variable.value.length == 1) {
				this.container.scene.autovar[this.data.variable.value] = this.choices[this.choice];
			} else {
				this.container.story.getVariable(this.data.variable.value).value = this.choices[this.choice];
			}

			this.flag = this.hideText();

			return;
		}

		this.flag = this.showText(this.data.text.value);

		if (!this.constructor.selectBox) {
			this.constructor.selectBox = new PIXI.Graphics();
		} else {
			this.constructor.selectBox.clear();
			this.constructor.selectBox.removeChildren();
		}

		if (!this.constructor.cursor) {
			this.constructor.cursor = new PIXI.Graphics();
			this.constructor.cursor.beginFill(0xFF3300);
			this.constructor.cursor.drawCircle(0, 0, 8);
			this.constructor.cursor.endFill();
			this.constructor.cursor.position.set(16, 16);
		}

		this.constructor.selectBox.addChild(this.constructor.cursor);

		this.choice = 0;
		this.choices = [];

		for (let i = 0; i < 4; i++) if (this.data["answer_"+i].value) {
			this.choices.push(this.data["value_"+i].value);
			let text = new PIXI.Text(this.data["answer_"+i].value, this.container.textBoxStyle);
			text.position.set(30, 8 + 22 * i);
			this.constructor.selectBox.addChild(text);
		}

		let boxHeight = this.choices.length * 22 + 16;
		this.constructor.selectBox.beginFill(this.container.backgroundColor)
			.drawRoundedRect(0, 0, 260, boxHeight, 16)
			.endFill();

		this.constructor.selectBox.position.set(this.container.width * this.container.s - 268, this.container.height * this.container.s - 112 - boxHeight);
		this.container.land.overlay.addChild(this.constructor.selectBox);

		return Runnable.PAUSE;
	}

	state() {
		if (this.container.keys.ArrowUp == 1) {
			if (--this.choice < 0) this.choice += this.choices.length;
		} else if (this.container.keys.ArrowDown == 1) {
			if (++this.choice == this.choices.length) this.choice -= this.choices.length;
		} else return;

		this.constructor.cursor.position.set(16, 16 + this.choice * 22);
	}
}

export default ActionTextChoice;
